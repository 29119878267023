import {LibrariesLoader} from '../utils/libraries-loader';

class CirclePayments {
  _librariesLoader = null;
  circleKeyUrl = '/circle-public-key.json';
  circlePublicKey = null;
  circlePublicKeyId = null;
  
  constructor(){
    this._librariesLoader = new LibrariesLoader;
  }
  
  // import { createMessage, encrypt as pgpEncrypt, readKey } from 'openpgp';
  processCircleTransaction( formData, onSuccess = ()=>{}) {
    const that = this;
    
    this._librariesLoader.loadLibrary('/assets/js/openpgp.5.0.1.min.js')
      .then(() => {
        if (that.circlePublicKey === null || that.circlePublicKeyId === null) {
          fetch(this.circleKeyUrl)
            .then(resp => {
              // you'll need to supply the function that checks the status here
              if (resp.status === 200) {
                return resp.json();
              } else {
                throw new Error(`Got back ${resp.status}`);
              }
            })
            .then(function (response) {
              var responseData = response;
              that.circlePublicKeyId = responseData.data.keyId;
              that.circlePublicKey = responseData.data.publicKey;
              // console.log('we have a key, let us process the transaction');
              // console.log(formData);
              processPaymentTransaction(that.circlePublicKeyId, that.circlePublicKey, formData, onSuccess);
              // that._processTransaction(that.circlePublicKeyId, that.circlePublicKey, formData, onSuccess);
            })["catch"](function (err) {
              alert(err);
              alert('unable to process transaction');
          });
        } else {
          // console.log('we already have a key, let us process the transaction');
          // console.log(formData);
          processPaymentTransaction(that.circlePublicKeyId, that.circlePublicKey, formData, onSuccess);
        }
      });
  }
  
  // _processTransaction(circlePublicKeyId, circlePublicKey, formData, onSuccess) {
  //
  //   console.log('we are in the transaction processor');
  //  
  //   (async () => {
  //    
  //     console.log('we are in the async part');
  //    
  //     const cardDetails = {
  //       number: formData.get('cardNumber'),
  //       cvv: formData.get('cvc')
  //     };
  //
  //     const message = await openpgp.createMessage({text: JSON.stringify(cardDetails)});
  //     const decodedPublicKey = await openpgp.readKey({armoredKey: atob(circlePublicKey)});
  //
  //     const encryptedData = await openpgp.encrypt({
  //       message: message,
  //       encryptionKeys: decodedPublicKey
  //     });
  //
  //     const encodedEncryptedData = btoa(encryptedData);
  //
  //     formData.append('paymentInfo', encodedEncryptedData);
  //     formData.delete('cardNumber');
  //     formData.delete('cvc');
  //
  //     formData.append('keyId', circlePublicKeyId);
  //
  //     $.ajax({
  //       type: "POST",
  //       dataType: 'json',
  //       url: '/',
  //       data: formData,
  //       contentType: false,
  //       processData: false,
  //       error: function (request, status, error) {
  //         console.log('we have an error!');
  //         console.log(request);
  //         console.log(status);
  //         console.log(error);
  //         alert(request.responseText);
  //       },
  //       success: function (data) {
  //
  //         console.log('are we successful?!');
  //         console.log(data);
  //
  //         if (data.success) {
  //           ui.toastSuccess('purchase completed!');
  //           onSuccess();
  //         } else if (data.error) {
  //           alert('there was a problem: ' + data.error);
  //         }
  //       },
  //     });
  //   })();
  // }
}

export {CirclePayments};