class BetaRequest {
  betaRequestTriggerSelector;

  constructor(selector = '.js-beta-request') {
    this.betaRequestTriggerSelector = selector;
  }

  init(){
    const that = this;
    $(this.betaRequestTriggerSelector).on('submit', function(e) {
      e.preventDefault();
      that.startSendFormProcess($(this));
    })
  }

  startSendFormProcess($element) {
    const data = $element.serialize()
      + '&' + site.csrfTokenName
      + '=' + site.csrfTokenValue
      + '&action=freeform/submit';

    $.ajax({
      type: "POST",
      url: '/freeform/submit',
      data: data,
      dataType: "json",
      beforeSend: function () {
        $('.js-request-beta-button').addClass('btn-variants--loader')
      },
    }).always(function (returned) {
      $('.js-request-beta-button').removeClass('btn-variants--loader')
    }).done(function (response) {
      if (response.success) {

        function random(max){
          return Math.random() * (max - 0) + 0;
        }

        let c = document.createDocumentFragment();
        for (let i=0; i<100; i++) {
          let styles = 'transform: translate3d(' + (random(1000) - 500) + 'px, ' + (random(400) - 300) + 'px, 0) rotate(' + random(720) + 'deg);\
                  background: hsla('+random(360)+',100%,50%,1);\
                  animation: bang 2000ms ease-out forwards;\
                  opacity: 0';

          let e = document.createElement("i");
          e.style.cssText = styles.toString();
          c.appendChild(e);
        }
        $('.beta-request--success').removeClass('hidden');
        $('.js-beta-request').addClass('hidden');
        $('.beta-request--success').append(c);
      } else {
        ui.toastError("An error occurred: we could not send your message. Please try again later!");
        console.error(data);
      }
    })
  }
}

export {BetaRequest};