class AuthModal {
  loginRegisterSelector = ".js-auth-modal";
  functionalityRequiresLogInSelector = ".js-requires-login";
  popup = null;

  constructor(triggerSelector) {
    if (triggerSelector) {
      this.loginRegisterSelector = triggerSelector;
    }
  }

  get loginHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>Welcome back!</h4>
</div>
<div class="auth-modal--tab-label">
    <div class="active">
        Login
    </div>
    <div class="js-auth-view" data-view="register">
        Sign up
    </div>
</div>
<form class="js-auth-form" data-type="login">
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="text" name="loginName" required class="field field--full-width field-username" placeholder="Username">
          <i class="icon-profile prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper password-wrapper">
          <input type="password" name="password" required class="field field--full-width field-password" id="password" placeholder="Password">
          <i class="icon-lock prefix-icon"></i>
          <div class="suffix-icon password-visibility">
            <i class="icon-eye-close toggle-password"></i>
          </div>
      </div>
    </div>
    <div class="auth-modal--secondary">
        <div class="auth-modal--secondary-keep-signed">
            <label><input type="checkbox" name="rememberMe" value="1" class="field-keep-signed"> Keep me signed in.</label>
        </div>
        <div class="auth-modal--secondary-forgot-password">
            <a class="js-auth-view reset-password" data-view="passwordRecovery">Forgot password?</a> 
        </div>
    </div>
    <div class="auth-modal--message"></div>
    <div class="auth-modal--button">
        <button class="js-login-button btn-primary btn-variants--full" type="submit">LOGIN</button>
    </div>
</form>          
`;
  }

  get registerHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>Join ARKH now. It’s free!</h4>
</div>
<div class="auth-modal--tab-label">
    <div class="js-auth-view" data-view="login">
        Login
    </div>
    <div class="active">
        Sign up
    </div>
</div>
<form class="js-auth-form" data-type="register">
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="text" name="first_name" required class="field field--full-width field-firstname" placeholder="First Name">
          <i class="icon-profile prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="text" name="last_name" required class="field field--full-width field-lastname" placeholder="Last Name">
          <i class="icon-profile prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--field-group field-group-number">
        <div class="field--with-icon-wrapper">
            <input type="text" name="phonenumber" class="field field--full-width field-phone-number" placeholder="Phone Number">
            <i class="icon-profile prefix-icon"></i>
        </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="text" name="username" required class="field field--full-width field-username" placeholder="Username">
          <i class="icon-profile prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="email" name="email" required class="field field--full-width field-email" placeholder="Email">
          <i class="icon-envelope prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper password-wrapper">
          <input type="password" name="password" required class="field field--full-width field-password" placeholder="Password" id="password">
          <i class="icon-lock prefix-icon"></i>
          <div class="suffix-icon password-visibility">
            <i class="icon-eye-close toggle-password"></i>
          </div>
      </div>
    </div>
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper password-wrapper">
          <input type="password" required class="field field--full-width field-confirmpassword" placeholder="Confirm Password" id="confirm-password">
          <i class="icon-lock prefix-icon"></i>
          <div class="suffix-icon confirm-password-visibility">
            <i class="icon-eye-close toggle-confirm-password"></i>
          </div>
      </div>
    <div class="auth-modal--field-group auth-modal--field-newsletter">
      <label><input type="checkbox" name="newsletter" value="1"> Keep me up to date with added features and updates</label>
    </div>
    <div class="auth-modal--message"></div>
    {{ craft.googleRecaptcha.render() }}
    <div class="auth-modal--button">
        <button class="js-register-button btn-primary btn-variants--full">SIGN UP</button>
    </div>
    <div class="auth-modal--secondary-link">
        <button class="js-auth-view" data-view="login">Already registered? Click here to login.</button>    
    </div>
</form>          
`;
  }

  get passwordRecoveryHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>Reset your password</h4>
</div>
<form class="js-auth-form" data-type="resetPassword">
    <div class="auth-modal--field-group">
      <div class="field--with-icon-wrapper">
          <input type="email" name="loginName" required class="field field--full-width field-email-reset" placeholder="Your email">
          <i class="icon-envelope prefix-icon"></i>
      </div>
    </div>
    <div class="auth-modal--message"></div>
    <div class="auth-modal--button">
        <button class="btn-primary btn-variants--full js-reset-button">Send a reset link</button>
    </div>
    <div class="auth-modal--secondary-link">
        <button class="js-auth-view" data-view="login">Did you remember your password?</button>    
    </div>
</form>          
`;
  }

  get registrationConfirmationHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>Your account has been created!</h4>
</div>
<div>
    <button type="button" class="js-auth-view text-primary-blue-500 font-bold hover:underline" data-type="login">Click here</button> to login
</div>
`;
  }

  get passwordResetSentConfirmationHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>A mail containing a reset link has been sent!</h4>
</div>
<div class="text-sm mb-6">
    <p>Open your email and click the reset link.</p>
    <p>This will open a page with a form field where you can set a new password .</p>
</div>
<div>
    <button onclick="swal.close(); return false;" class="btn-action">CLOSE</button>
</div>
`;
  }

  get functionalityRequiresLoginHtml() {
    return `
<div class="auth-modal--logo">
    <img src="/static-assets/images/logo-white.svg" alt="">
</div>
<div class="auth-modal--title">
    <h4>This functionality requires login</h4>
</div>
<div>
    <a type="button" href="/login" class="text-primary-blue-500 font-bold hover:underline">Click here</button> to login
</div>
`;
  }

  getAuthView(viewType = "login") {
    return this[viewType + "Html"];
  }

  init() {
    const that = this;

    that.popup = Swal.mixin({
      title: null,
      customClass: {
        container: "auth-modal",
      },
      showConfirmButton: false,
      showCloseButton: true,
      icon: null,
    });

    let formInitializationTime = new Date();

    $(this.loginRegisterSelector).on("click", function (e) {
      e.preventDefault();
      const type = $(e.target).data("type") || "login";
      that.popup.fire({
        html: type ? that.getAuthView(type) : that.loginHtml,
      });
    });

    $(document).on("click", ".js-auth-view", function (e) {
      const $target = $(e.target);
      that.popup.update({
        html: that.getAuthView($target.data("view")),
      });
    });

    $(document).on("submit", ".js-auth-form", function (e) {
      const type = $(this).data("type");
      if (type === "login") {
        that.login($(this));
      } else if (type === "register") {
        that.register($(this));
      } else if (type === "resetPassword") {
        that.resetPassword($(this));
      }
      return false;
    });

    $(document).on(
      "click",
      this.functionalityRequiresLogInSelector,
      function (e) {
        e.stopPropagation();
        that.popup.fire({
          html: that.getAuthView("functionalityRequiresLogin"),
        });
      }
    );

    function toggleVisibility(input, icon) {
      if (input.type === "password") {
        input.type = "text";
        input.focus();
        icon.removeClass("icon-eye-close");
        icon.addClass("icon-eye");
      } else {
        input.type = "password";
        input.focus();
        icon.removeClass("icon-eye");
        icon.addClass("icon-eye-close");
      }
    }

    $(document).on("click", ".password-visibility", function (e) {
      const password = document.getElementById("password");
      toggleVisibility(password, $(".toggle-password"));
    });

    $(document).on("click", ".confirm-password-visibility", function (e) {
      const confirmPassword = document.getElementById("confirm-password");
      toggleVisibility(confirmPassword, $(".toggle-confirm-password"));
    });
  }

  login($form) {
    const data =
      $form.serialize() +
      "&" +
      site.csrfTokenName +
      "=" +
      site.csrfTokenValue +
      "&action=users/login";
    const $messageElm = $(".auth-modal--message", $form);
    const $button = $(".js-login-button", $form);

    $messageElm.hide().removeClass("success").removeClass("error").text("");
    showLoader();

    function showLoader() {
      $button.addClass("btn-variants--loader");
    }

    function hideLoader() {
      $button.removeClass("btn-variants--loader");
    }

    function stopLoaderAndShowMessage(message, type = "error") {
      $messageElm
        .removeClass("error")
        .removeClass("success")
        .addClass(type)
        .text(message)
        .show();
      hideLoader();
    }

    $.ajax({
      type: "POST",
      contentType: "application/x-www-form-urlencoded",
      url: "/",
      data: data,
      success: function (result) {
        if (result.success && result.success === true) {
          window.location.href = site.siteUrl;
        } else {
          stopLoaderAndShowMessage("Username or password not valid.");
        }
      },
      error: function () {
        stopLoaderAndShowMessage(
          "An error occurred while logging in. Please try again."
        );
      },
      dataType: "json",
    });
  }

  register($form) {
    const data =
      $form.serialize() +
      "&" +
      site.csrfTokenName +
      "=" +
      site.csrfTokenValue +
      "&action=actions/arkh-api/user/register-api";
    const $messageElm = $(".auth-modal--message", $form);
    const $button = $(".js-register-button", $form);
    const $usernameField = $(".field-username", $form);
    const $emailField = $(".field-email", $form);
    const $passwordField = $(".field-password", $form);
    const $passwordConfirm = $(".field-confirmpassword", $form);
    let submissionIsValid = true;
    const minPasswordLength = 5;

    $messageElm.hide().removeClass("success").removeClass("error").text("");
    showLoader();

    function showLoader() {
      $button.addClass("btn-variants--loader");
    }

    function hideLoader() {
      $button.removeClass("btn-variants--loader");
    }

    function stopLoaderAndShowMessage(message, type = "error") {
      $messageElm
        .removeClass("error")
        .removeClass("success")
        .addClass(type)
        .text(message)
        .show();
      hideLoader();
    }

    let submissionTime = new Date() - formInitializationTime;

    if (submissionTime < 5000) {
      submissionIsValid = false;
      window.location.href = "/";
    }

    if ($(".field-phone-number").val()) {
      submissionIsValid = false;
      window.location.href = "/";
    }

    if (!this.usernameIsValid($usernameField.val())) {
      stopLoaderAndShowMessage(
        "The username is not valid. " +
          "A valid username is only made by an alphanumeric word (characters and numbers without special characters and spaces)." +
          "eg: johnny5"
      );
      submissionIsValid = false;
    }

    if (!this.emailIsValid($emailField.val())) {
      stopLoaderAndShowMessage(
        "The email address is not valid. Please enter a valid email."
      );
      submissionIsValid = false;
    }

    if ($passwordField.val().length < minPasswordLength) {
      stopLoaderAndShowMessage(
        "The password must be minimum " +
          minPasswordLength +
          " characters long. " +
          "A good password contains also special characters like !*# and upper case letters."
      );
      submissionIsValid = false;
    }

    if ($passwordField.val() !== $passwordConfirm.val()) {
      stopLoaderAndShowMessage(
        "Password and password confirm should be the same."
      );
      submissionIsValid = false;
    }

    const that = this;

    if (submissionIsValid) {
      $.ajax({
        type: "POST",
        url: "actions/arkh-api/user/register-api",
        data: data,
        success: function (result) {
          if (result.success && result.success === true) {
            that.popup.update({
              html: that.getAuthView("registrationConfirmation"),
            });
          } else {
            stopLoaderAndShowMessage(
              result.message ||
                "An error occurred while creating the user account. Please try again."
            );
          }
        },
        error: function (xhr, status, error) {
          stopLoaderAndShowMessage(
            "An error occurred while creating the user account. Please try again."
          );
          console.error(error);
        },
        dataType: "json",
      });
    }
  }

  resetPassword($form) {
    const data =
      $form.serialize() +
      "&" +
      site.csrfTokenName +
      "=" +
      site.csrfTokenValue +
      "&action=users/send-password-reset-email";
    const $messageElm = $(".auth-modal--message", $form);
    const $button = $(".js-reset-button", $form);
    const that = this;

    $messageElm.hide().removeClass("success").removeClass("error").text("");
    showLoader();

    function showLoader() {
      $button.addClass("btn-variants--loader");
    }

    function hideLoader() {
      $button.removeClass("btn-variants--loader");
    }

    function stopLoaderAndShowMessage(message, type = "error") {
      $messageElm
        .removeClass("error")
        .removeClass("success")
        .addClass(type)
        .text(message)
        .show();
      hideLoader();
    }

    $.ajax({
      type: "POST",
      url: "/",
      data: data,
      success: function (result) {
        if (result.success && result.success === true) {
          that.popup.update({
            html: that.getAuthView("passwordResetSentConfirmation"),
          });
        } else {
          stopLoaderAndShowMessage(
            result.error ||
              result.message.message ||
              "An error occurred while requesting the password reset. Please try again."
          );
        }
      },
      error: function (xhr, status, error) {
        stopLoaderAndShowMessage(
          "An error occurred while requesting the password reset. Please try again."
        );
        console.error(error);
      },
      dataType: "json",
    });
  }

  emailIsValid(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  usernameIsValid(username) {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(username);
  }
}

export { AuthModal };
