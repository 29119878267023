export default function loadHtmlContent(endpoint, baseUrl) {
  let result = null;
  if (!baseUrl) {
    baseUrl = site.siteUrl;
  }

  $.ajax({
    url: baseUrl + endpoint,
    type: "GET",
    async: false,
    dataType: "html", // added data type
    success: function (res) {
      result = res;
    },
  });

  return result;
}
