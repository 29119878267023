import htmlLoader from "../utils/load-html-content";

class PickaxeWhitelist {
  reserveItemTriggerSelector = ".js-whitelist-modal";
  popup = null;

  constructor(selector = null) {
    if (selector) {
      this.reserveItemTriggerSelector = selector;
    }
  }

  init() {
    const that = this;

    this.popup = Swal.mixin({
      title: null,
      customClass: {
        container: "form-modal",
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      icon: null,
    });

    $(document).on("click", ".js-metamask-list", function (e) {
      $(".js-metamask-list").addClass("btn-variants--loader");
      if (typeof window.ethereum !== "undefined") {
        ethereum
          .request({ method: "eth_requestAccounts" })
          .then((response) => {
            $(".other-wallet").addClass("hidden");
            $("#walletAddress").val(response[0]);

            const connectButton = document.getElementById("metaMask");
            connectButton.innerHTML = "Connected";
            let formData = $(".whitelist-form").serialize();

            $.ajax({
              type: "POST",
              url: "/freeform/submit",
              data: formData,
              beforeSend: function () {
                $(".js-metamask-list").addClass("btn-variants--loader");
              },
            })
              .always(function (returned) {
                $(".js-metamask-list").removeClass("btn-variants--loader");
              })
              .done(function (response) {
                if (response.success) {
                  $(".listed").removeClass("hidden");
                  $(".unlisted").addClass("hidden");
                  ui.toastSuccess(
                    "Your MetaMask wallet address has been added to the whitelist!"
                  );
                }
              })
              .fail(function (data) {
                ui.toastError(
                  "An error occurred: we could not add that wallet address to the whitelist. Please try again later!"
                );
                console.error(data);
              });
          })
          .catch((error) => {
            ui.toastError(
              "An error occurred: your MetaMask wallet may be locked. Please unlock to proceed."
            );
          });
      } else {
        ui.toastError(
          "Could not detect MetaMask. Please install MetaMask extension to continue."
        );
      }
    });

    $(document).on("submit", ".whitelist-form", function (e) {
      e.preventDefault();
      const data = $(this).serialize();

      $.ajax({
        type: "POST",
        url: "/freeform/submit",
        data: data,
        beforeSend: function () {
          $(".js-allowlist-button").addClass("btn-variants--loader");
        },
      })
        .always(function (returned) {
          $(".js-allowlist-button").removeClass("btn-variants--loader");
        })
        .done(function (response) {
          if (response.success) {
            ui.toastSuccess(
              "Your wallet address has been added to the whitelist!"
            );
          }
        })
        .fail(function (data) {
          ui.toastError(
            "An error occurred: we could not send the text message. Please try again later!"
          );
          console.error(data);
        });
    });

    $(this.reserveItemTriggerSelector).on("click", function (e) {
      that.popup.fire({
        html: that.getView(),
      });
    });
  }

  getView() {
    return htmlLoader("reserve/pickaxe-whitelist");
  }
}

export { PickaxeWhitelist };
