import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from  "three/examples/jsm/loaders/GLTFLoader.js";
import { CSS3DRenderer, CSS3DObject, CSS3DSprite } from 'three/addons/renderers/CSS3DRenderer.js';
// import { OrbitControls } from "../jsm/controls/OrbitControls.js";
// import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

class RenderItem {
  renderItemTriggerSelector = "#featured-creation";
  actionUrlListItemById = '/actions/arkh-api/products/list-item-by-id';
  actionUrlGetCreationData = '/actions/arkh-api/item-data/get-creation-data';

  constructor(selector = null) {
    if (selector) {
      this.renderItemTriggerSelector = selector;
    }
  }

  init() {
    const that = this;
    if(document.getElementById('featured-creation')) {
      const itemId = $('#featured-creation').data('id');
      $("#item-spinner").removeClass('hidden');
      
      that.startTime = new Date();

      if (!itemId) {
        throw new Error('Item id is missing from the data attribute data-item-id.');
      }

      that.queryCreation(itemId)
        .then(responseData => {
          that.getCreationData(responseData)
            .then(function (response) {
              console.log('response', response);
              if (response.voxel) {
                console.log('voxel', response);
                that.voxelWrapper(response);
              } else if (response.creationData) {
                console.log('creationData', response.creationData);
                if(that.isImageFile(response.creationData)){
                  console.log('is image');
                  $('.item-component--picture').removeClass('hidden');
                  $('#featured-creation').addClass('hidden');
                  $("#item-spinner").addClass('hidden');
                  // that.imageWrapper(response);
                } else if(that.is3dModel(response.creationData)){
                  console.log('3d model');
                  that.modelWrapper(response);
                }else {
                  $('.item-component--picture').removeClass('hidden');
                  $('#featured-creation').addClass('hidden');
                  $("#item-spinner").addClass('hidden');
                  // ui.popup({
                  //   title: null,
                  //   icon: 'error',
                  //   html: 'An error happened while loading the preview'
                  // })
                }
              } else if (that.isImageFile(response.creationImage)) {
                $('.item-component--picture').removeClass('hidden');
                $('#featured-creation').addClass('hidden');
                $("#item-spinner").addClass('hidden');
                // Fallback to the image thumb if noting is found
                // that.hideLoader();
                // that.modelIsLoading = false;
                // that.showImage(response.creationImage, ()=>{that.closeModal()});
                console.log('last option');
              } else {
                ui.popup({
                  title: null,
                  icon: 'error',
                  html: 'An error happened while loading the preview'
                })
              }
            })
            .catch((response) => {
              console.log('creation data', response);
              that.errorUi(itemId)
            });
        })
        .catch((response) => {
          console.log('query log', response);
          that.errorUi(itemId)
        })
    }
  }

  getCreationData(responseData) {
    const that = this;
    return new Promise((resolve, reject) => {
      const creationImage = responseData.creationImage;
      const creationData = responseData.creationData;
      const productReference = responseData.productReference;

      $.ajax({
        url: that.actionUrlGetCreationData,
        method: 'POST',
        data: {
          productReference: productReference
        },
        dataType: 'json',
      })
        .done(function (response) {
          if (response.success !== undefined) {
            resolve({
              creationImage: creationImage,
              creationData: creationData,
              voxel: response.item || null
            });
          } else {
            reject("response.success is false");
          }
        })
        .fail(function (jqXHR, textStatus) {
          reject({
            jqXHR: jqXHR,
            textStatus: textStatus
          });
        });
    })
  }
  
  queryCreation(itemId) {
    const that = this;

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: that.actionUrlListItemById,
        data: {
          sessionToken: 'n/a',
          username: 'n/a',
          id: itemId
        },
        dataType: 'json',
      })
        .done(function (response) {
          resolve(response);
        })
        .fail(function (qXHR, textStatus) {
          reject({
            jqXHR: jqXHR,
            textStatus: textStatus
          });
        });
    })
  }

  errorUi = function (id) {
    console.error('Could not retrieve item ' + id);
    ui.toastError('An error occured while retrieving the creation data. Please try again.');
  }

  isFileType(filePath, extensionsArray){
    for (let i = 0; i < extensionsArray.length; i++) {
      if (filePath.includes(extensionsArray[i])) {
        return true;
      }
    }

    return false;
  }

  is3dModel(filePath){
    const extensions = [
      '.glb',
      '.gltf'
    ]
    return this.isFileType(filePath, extensions)
  }

  isImageFile(filePath) {
    const extensions = [
      '.jpg',
      '.gif',
      '.png',
      '.jpeg'
    ];

    return this.isFileType(filePath, extensions);
  }
  
  voxelWrapper(response) {
    let scene, camera, renderer, mesh;
    renderVoxelCreation(response);

    function renderVoxelCreation(response) {
      camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 99999 );
      camera.position.set( 0, 0, 20);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xFEFBFF);
      // scene.fog = new THREE.Fog(0xFBFBFF, 200, 1800);

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 400, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(0, 200, 100);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 180;
      directionalLight.shadow.camera.bottom = -100;
      directionalLight.shadow.camera.left = -120;
      directionalLight.shadow.camera.right = 120;
      scene.add(directionalLight);

      let voxelColors = {};

      for(let color of response.voxel['Blocks']) {
        voxelColors[color.id] = color.hex;
      }
      
      let group = new THREE.Group();
      for(let voxel of response.voxel['Objects'][0].voxels) {
        let color = voxel.voxelBlockId;
        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshBasicMaterial( { color: voxelColors[color]} );
        const mesh = new THREE.Mesh( geometry, material );
        mesh.castShadow = true;
        mesh.position.y = voxel.position.y;
        mesh.position.x = voxel.position.x;
        mesh.position.z = voxel.position.z;
        group.add(mesh);
      }
      
      // group.position.set(0, 0, 0);
      group.rotation.y = Math.PI;
      
      camera.lookAt(group.position);

      console.log('camera', camera.position.distanceTo(group.position));

      var box = new THREE.Box3();
      box.setFromObject( group );
      
      const vectorSize = box.getSize(new THREE.Vector3());
      console.log(vectorSize);

      camera.position.x = vectorSize.x * 1.5;
      camera.position.z = vectorSize.z * 2;
      camera.position.y = vectorSize.y * 2;
      scene.add(group);


      const controls = new OrbitControls(camera, document.getElementById('featured-creation'));
      controls.enableDamping = true;
      
      const renderer = new THREE.WebGLRenderer();
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      renderer.setSize(window.innerWidth, window.innerHeight);
      // renderer.shadowMap.enabled = true;
      document.getElementById('featured-creation').appendChild(renderer.domElement);
      

      window.addEventListener("resize", onWindowResize);

      const clock = new THREE.Clock();
      
      function tick() {
        const elapsedTime = clock.getElapsedTime();
        group.rotation.y = elapsedTime;
        // group.rotation.x = elapsedTime;
        
        renderer.render(scene, camera);

        window.requestAnimationFrame(tick);
      }
      
      tick();
      $("#item-spinner").addClass('hidden');
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    // function animate() {
    //   requestAnimationFrame(animate);
    //   renderer.render(scene, camera);
    // }
  }


  modelWrapper(response) {
    let scene, camera, renderer, controls, mesh;
    var objects = [];
    renderModelCreation(response);

    function renderModelCreation(response) {
      console.log('render', response.creationData);
      camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
      camera.position.set( 0, 0, 8);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xFEFBFF);
      

      const loader = new GLTFLoader();
      var box = new THREE.Box3()
      loader.load( response.creationData.split('?')[0], function ( gltf ) {
        scene.add( gltf.scene );
        objects.push(gltf.scene);
        box.setFromObject( gltf.scene );
        const vectorSize = box.getSize(new THREE.Vector3());
        console.log(vectorSize);
        camera.position.z = vectorSize.z * 5;
        camera.position.x = vectorSize.x * 0.5;
        camera.position.y = vectorSize.y * 2;

        tick(gltf.scene);
      } );

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 400, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(0, 200, 100);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 180;
      directionalLight.shadow.camera.bottom = -100;
      directionalLight.shadow.camera.left = -120;
      directionalLight.shadow.camera.right = 120;
      scene.add(directionalLight);
      
      renderer = new THREE.WebGLRenderer( { antialias: true } );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.outputEncoding = THREE.sRGBEncoding;

      controls = new OrbitControls( camera, renderer.domElement );
      controls.enableDamping = true;

      
      document.getElementById("featured-creation").appendChild(renderer.domElement);
      

      window.addEventListener("resize", onWindowResize);

      const clock = new THREE.Clock();

      function tick(gltf) {
        const elapsedTime = clock.getElapsedTime();

        gltf.rotation.y = elapsedTime * 0.1;
        gltf.rotation.x = elapsedTime * 0.1;

        controls.update();

        renderer.render(scene, camera);

        window.requestAnimationFrame(() => tick(gltf));
      }

      $("#item-spinner").addClass('hidden');
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      controls.update();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }
  }

  imageWrapper(response) {
    console.log('image', response);
    let scene, camera, renderer, mesh;
    renderVoxelCreation(response);
    animate();
    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function renderVoxelCreation(response) {
      const loader = new THREE.ImageLoader();
      camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 10000 );
      camera.position.set( 50, 100, 50 );

      scene = new THREE.Scene();
      scene.background = new THREE.Color( 0xffffff );

      const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      loader.load(
        // resource URL
        response.creationData,

        // onLoad callback
        function ( image ) {
          // use the image, e.g. draw part of it on a canvas
          // const canvas = document.createElement( 'canvas' );
          // const context = canvas.getContext( '2d' );
          // context.drawImage( image, 100, 100 );
          scene.add(image);
        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        function () {
          console.error( 'An error happened.' );
        }
      );

      // const geometry = new THREE.BoxGeometry( 40, 40, 2 );
      // const material = new THREE.MeshBasicMaterial( { color: response.creationData} );
      // const cubeMaterial = new THREE.MeshLambertMaterial( { color: 0xffffff, map: new THREE.TextureLoader().load( response.creationData ) } );
      // const mesh = new THREE.Mesh( geometry, material );
      // mesh.castShadow = true;
      // mesh.position.y = 25;
      // scene.add(mesh);

      renderer = new CSS3DRenderer();
      renderer.setSize( window.innerWidth, window.innerHeight );
      document.getElementById("item-model-id").appendChild(renderer.domElement);

      const controls = new OrbitControls( camera, renderer.domElement );
      controls.target.set(0, 25, 0);
      controls.enableZoom = true;
      controls.update();

      window.addEventListener("resize", onWindowResize);
    }

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
  }
}

export { RenderItem };