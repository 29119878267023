import htmlLoader from "../utils/load-html-content";

class ReportCreation {
  reportCreationTriggerSelector = ".js-report-creation";
  popup = null;

  constructor(selector = null) {
    if (selector) {
      this.reportCreationTriggerSelector = selector;
    }
  }

  init() {
    const that = this;
    let reportedTitle = "";
    let reportedSlug = "";
    let reportedCreator = "";

    this.popup = Swal.mixin({
      title: null,
      customClass: {
        container: "report-form",
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      icon: null,
    });

    $(document).on("click", ".js-more-options", function (e) {
      e.stopPropagation();
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(".js-more-options").removeClass("active");
        $(this).addClass("active");
      }
    });

    $(document).on("click", ".js-report-creation", function (e) {
      e.stopPropagation();
      $(".js-more-options").removeClass("active");
      reportedTitle = $(this).attr("data-title");
      reportedSlug = $(this).attr("data-id");
      reportedCreator = $(this).attr("data-creator");

      that.popup.fire({
        html: that.getView(),
      });
    });

    $(document).on("click", function (e) {
      $(".js-more-options").removeClass("active");
    });

    $(document).on("submit", ".js-report-form", function (e) {
      e.preventDefault();
      let $form = $(this);

      let form = document.querySelector(".js-report-form");
      let reportedTitleInput = form.querySelector(
        'input[name^="reportedTitle"]'
      );
      let reportedUrlInput = form.querySelector('input[name^="reportedUrl"]');
      let reportedCreatorInput = form.querySelector(
        'input[name^="reportedCreator"]'
      );
      reportedTitleInput.value = reportedTitle;
      reportedUrlInput.value = reportedSlug;
      reportedCreatorInput.value = reportedCreator;

      const data =
        $form.serialize() +
        "&" +
        site.csrfTokenName +
        "=" +
        site.csrfTokenValue +
        "&action=freeform/submit";

      $.ajax({
        type: "POST",
        url: "/freeform/submit",
        data: data,
        dataType: "json",
        beforeSend: function () {
          $(".js-report-button").addClass("btn-variants--loader");
        },
      })
        .always(function (returned) {
          $(".js-report-button").removeClass("btn-variants--loader");
        })
        .done(function (response) {
          if (response.success) {
            ui.toastSuccess("Issue has been reported successfully.");
          }
        })
        .fail(function (data) {
          ui.toastError(
            "An error occurred: we could not report this issue. Please try again later!"
          );
          console.error(data);
        });
    });
  }

  getView() {
    return htmlLoader("report/report-creation");
  }
}

export { ReportCreation };
