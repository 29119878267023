class LibrariesLoader {
  loadLibrary(src, force = false){
    return new Promise((resolve, reject) => {
      const scripts = Array
        .from(document.querySelectorAll('script'))
        .map(scr => scr.src);
      
      let shouldLoad = force ? true : !scripts.includes(src);
      if (shouldLoad) {
        const tag = document.createElement('script');
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
        tag.onload = resolve;
      }
    });
  }
}

export {LibrariesLoader};