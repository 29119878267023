class EditProfile {

  profileFormSelector = '.js-profile-form';

  constructor(selector = null) {
    if(selector){
      this.profileFormSelector = selector;
    }
  }

  init() {
    
    $(this.profileFormSelector).on('submit', function(e) {
      e.preventDefault();
      
      if($('.profile-button').hasClass('btn-variants--loader')){
        return;
      }
      
      let $form = $(this);
      const data = $form.serialize()
        + '&' + site.csrfTokenName
        + '=' + site.csrfTokenValue
        + '&action=arkh-custom-features/user-data-updater/update-current-user-data';

      $.ajax({
        type: "POST",
        url: '/',
        data: data,
        dataType: "json",
        beforeSend: function () {
          $('.profile-button')
            .addClass('btn-variants--loader')
            .addClass('btn-variants--disabled')
        },
      }).always(function (returned) {
        $('.profile-button')
          .removeClass('btn-variants--loader')
          .removeClass('btn-variants--disabled');
      }).done(function (response) {
        if (response.success) {
          ui.toastSuccess('Changes have been made to profile').then(function () {
            window.location.reload();
          })
        }
        else {
          if(response.success === false && response.message){
            ui.toastError(response.message);
          }
        }
      }).fail(function (data) {
        ui.toastError("An error occurred: we could not update your profile. Please try again later!");
        console.error(data);
      })
    });
  }
}

export {EditProfile};