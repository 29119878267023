/**
 * Delay the .keyup() handler until the user stops typing
 * https://stackoverflow.com/a/1909508
 *
 * @param callback
 * @param ms
 * @returns {(function(): void)|*}
 */
export default function delay(callback, ms) {
  var timer = 0;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}
