import delay from "../utils/delay";

class Search {
  settings = {};

  constructor(settings) {
    this.settings = settings;
  }

  init() {
    const settings = this.settings;

    const $searchField = $(settings.searchFieldSelector);
    const $searchResultsWrapper = $(settings.searchResultsWrapper);
    const $searchResults = $(settings.searchResults);
    const $searchClearButton = $(settings.searchClearButton);
    const $loadingImage = $(settings.loadingImage);

    if ($searchField.val() !== "") {
      $searchClearButton.show();
    }

    const hideSearchResults = function () {
      $searchResultsWrapper.hide();
      $searchClearButton.hide();
      $loadingImage.hide();
    };

    const clearSearchResults = function () {
      $searchResults.empty();
    };

    const clearSearchField = function () {
      $searchField.val("");
    };

    $searchClearButton.on("click", (e) => {
      clearSearchResults();
      hideSearchResults();
      clearSearchField();
    });

    const userSearchItem = `
<div class="search-result-user js-card" data-url="/{{ username }}">
    <div class="data">
      <div class="picture">
          <img class="element-avatar--circle" src="{{ photo }}" alt="{{ fullName }} avatar picture">
      </div>
      <div class="infos">
        <div class="name">
            <span>{{ fullName }}</span>
        </div>
        <div class="username">
            <span>@{{ username }}</span>
        </div>
      </div>
    </div>
    <div class="action-button">
        <span class="btn-action">View</span>
    </div>
</div>
`;

    const searchItem = `
<div class="search-result-item js-card" data-url="/item/{{ creationSlug }}">
    <div class="data">
      <div class="picture">
          <img class="element-avatar--square" src="{{ image }}" alt="{{ fullName }} item image">
      </div>
      <div class="infos">
        <div class="name">
            <span>{{ title }}</span>
        </div>
        <div class="username">
            <span class="by">by</span><span class="username">@{{ creatorUsername }}</span>
        </div>
      </div>
    </div>
    <div class="action-button">
        <span class="btn-action">View</span>
    </div>
</div>
`;

    const userSearchItemHandlebarTemplate = Handlebars.compile(userSearchItem);
    const searchItemHandlebarTemplate = Handlebars.compile(searchItem);

    const searchFunction = function (e) {
      var search = $(this).val();
      if (search !== "") {
        $searchResultsWrapper.show();
        $searchClearButton.show();
        $loadingImage.show();
      } else {
        hideSearchResults();
      }

      if (search.length > 0) {
        $.ajax({
          url: site.searchEndpoint,
          type: "post",
          data: {
            query: search,
            limit: 100,
          },
          dataType: "json",
          success: function (response) {
            // console.log(response);
            $loadingImage.hide();
            var resultLength = response.data.length;
            if (resultLength) {
              clearSearchResults();
              for (var i = 0; i < resultLength; i++) {
                var item = response.data[i];
                // var title = response.data[i]['title'] || null;
                var searchType = item["searchType"] || null;
                if (searchType === "user") {
                  $searchResults.append(userSearchItemHandlebarTemplate(item));
                }
                if (searchType === "creation") {
                  $searchResults.append(searchItemHandlebarTemplate(item));
                }
              }
            }
          },
        });
      }
    };

    $searchField.keyup(delay(searchFunction, 500));
  }
}

export { Search };
