class SendForm {
  sendFormTriggerSelector;

  constructor(selector = '.js-contact-form') {
    this.sendFormTriggerSelector = selector;
  }

  init(){
    const that = this;
    $(this.sendFormTriggerSelector).on('submit', function(e) {
      e.preventDefault();
      that.startSendFormProcess($(this));
    })
  }

  startSendFormProcess($element) {
    const data = $element.serialize()
      + '&' + site.csrfTokenName
      + '=' + site.csrfTokenValue
      + '&action=freeform/submit';

    $.ajax({
      type: "POST",
      url: '/freeform/submit',
      data: data,
      beforeSend: function () {
        $('.js-send-contact-button').addClass('btn-variants--loader')
      },
    }).always(function (returned) {
      $('.js-send-contact-button').removeClass('btn-variants--loader')
    }).done(function (response) {
      if (response.success) {
        ui.toastSuccess('Your message has been sent successfully!').then(function () {
          window.location.reload();
        })
      }
    }).fail(function (data) {
      ui.toastError("An error occurred: we could not send your message. Please try again later!");
      console.error(data);
    })
  }
}

export {SendForm};