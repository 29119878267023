import htmlLoader from "../utils/load-html-content";

class ReserveController {
  reserveItemTriggerSelector = ".js-reserve-controller";
  popup = null;

  constructor(selector = null) {
    if (selector) {
      this.reserveItemTriggerSelector = selector;
    }
  }

  init() {
    const that = this;

    this.popup = Swal.mixin({
      title: null,
      customClass: {
        container: "reserve-controller",
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      icon: null,
    });

    $(document).on("submit", ".js-reserve-form", function (e) {
      e.preventDefault();
      let $form = $(this);

      let honeypotHash = $(".js-reserve-button").attr("data-honeypot-hash");
      let honeypotName = $(".js-reserve-button").attr("data-honeypot-name");
      let form = document.querySelector("form");
      let honeypotInput = form.querySelector('input[name^="arkh_data"]');
      honeypotInput.setAttribute("id", honeypotName);
      honeypotInput.setAttribute("name", honeypotName);
      honeypotInput.value = honeypotHash;

      const data =
        $form.serialize() +
        "&" +
        site.csrfTokenName +
        "=" +
        site.csrfTokenValue +
        "&action=freeform/submit";

      $.ajax({
        type: "POST",
        url: "/freeform/submit",
        data: data,
        dataType: "json",
        beforeSend: function () {
          $(".js-reserve-button").addClass("btn-variants--loader");
        },
      })
        .always(function (returned) {
          $(".js-reserve-button").removeClass("btn-variants--loader");
        })
        .done(function (response) {
          if (response.success) {
            ui.toastSuccess(
              "Your reservation has been submitted successfully!"
            );
          }
        })
        .fail(function (data) {
          ui.toastError(
            "An error occurred: we could not add you to the list. Please try again later!"
          );
          console.error(data);
        });
    });

    $(this.reserveItemTriggerSelector).on("click", function (e) {
      that.popup.fire({
        html: that.getView(),
      });
    });
  }

  getView() {
    return htmlLoader("reserve/reserve-controller");
  }
}

export { ReserveController };
