import htmlLoader from "../utils/load-html-content";
import { CirclePayments } from "../payments/circle-payments";

class DigitalProductCheckout {
  productSelector = ".js-product-checkout";
  popup = null;
  templates = {
    address: "checkout/digital-product/1-address",
    payment: "checkout/digital-product/2-payment",
    confirm: "checkout/digital-product/3-confirm",
  };
  forgetCartActionUrl = "/actions/arkh-custom-features/cart/forget-carts";
  updateCartActionUrl = "commerce/cart/update-cart";
  productData = {};
  circlePayments = null;

  constructor(selector = null) {
    if (selector) {
      this.productSelector = selector;
    }
  }

  init() {
    const that = this;
    this.circlePayments = new CirclePayments();

    this.popup = Swal.mixin({
      title: null,
      customClass: {
        container: "digital-product-checkout",
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      icon: null,
      allowOutsideClick: this.closeModalWarning,
      allowEscapeKey: false,
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
    });

    $(this.productSelector).on("click", function (e) {
      const $elm = $(this);
      that.productData["productSlug"] = $elm.data("product-slug");
      that.productData["productId"] = $elm.data("product-id");
      that.productData["product-image"] = $elm.data("product-image");
      that.productData["confirm-purchase-button-label"] =
        $elm.data("confirm-purchase-button-label") || null;

      that.buyItem();
    });

    $(document).on("click", ".js-close-checkout-confirm", function (e) {
      if (that.closeModalWarning()) {
        swal.close();
      }
    });

    $(document).on("click", ".js-checkout-view", function (e) {
      const $target = $(e.target);
      that.popup.update({
        html: that.getView($target.data("view")),
      });
    });

    $(document).on("submit", ".js-checkout-form", function (e) {
      e.preventDefault();
      const $form = $(this);

      // var formData = new FormData($form[0]);

      const type = $form.data("type");
      if (type === "address") {
        const phoneNumber = $("#checkout--phone-number").val();
        const zipCode = $("#checkout--zip-code").val();
        const countryVal = $("#checkout--country-region")
          .find(":selected")
          .val();

        if (countryVal == 183) {
          swal.close();
          alert("We are currently not offering this service in your location");
        } else {
          (async () => {
            const where = encodeURIComponent(
              JSON.stringify({
                postalCode: zipCode,
              })
            );
            const response = await fetch(
              `https://parseapi.back4app.com/classes/Russiazipcode_Russia_Zip_Code?count=1&limit=0&where=${where}`,
              {
                headers: {
                  "X-Parse-Application-Id":
                    "7iR2uPBiwdLOp3ajF6B0Y8UTpl9S66kyDMwyVnCt", // This is your app's application id
                  "X-Parse-REST-API-Key":
                    "QYvEGmPoIAEEUGlbs5Ui1RvFrurxdDqaldlGWjNo", // This is your app's REST API key
                },
              }
            );
            const data = await response.json();
            if (data.count > 0) {
              swal.close();
              alert(
                "We are currently not offering this service in your location"
              );
            } else {
              $.ajax({
                url: `/actions/twilio-sms/sms/number?q=${phoneNumber}`,
                method: "Get",
                success: function (responseData) {
                  if (responseData["success"]) {
                    if (responseData["code"] != "RU") {
                      that
                        .submitAjaxForm($form)
                        .then(function (data) {
                          return that.showPaymentForm();
                        })
                        .catch(function (error) {
                          console.log(error);
                          alert(
                            "An error happened while saving the billing address. Please try again. If the error persist please contact us."
                          );
                        });
                    } else {
                      swal.close();
                      alert(
                        "We are currently not offering this service in your location"
                      );
                    }
                  } else {
                    alert("Could not get validation with this phone number");
                  }
                },
                error: function (xhr, type) {
                  that
                    .submitAjaxForm($form)
                    .then(function (data) {
                      return that.showPaymentForm();
                    })
                    .catch(function (error) {
                      console.log(error);
                      alert(
                        "An error happened while saving the billing address. Please try again. If the error persist please contact us."
                      );
                    });
                },
              });
            }
          })();
        }
      }
      if (type === "payment") {
        var form = $(this)[0]; // You need to use standard javascript object here
        console.log("we found the form");
        console.log(form);
        var formData = new FormData(form);
        console.log("with the associated formdata");
        console.log(formData);

        that.submitPaymentForm(formData).catch(function (error) {
          console.log(error);
          alert(
            "An error happened while performing the payment. Please try again. If the error persist please contact us."
          );
        });
      }

      return false;
    });
  }

  getView(templateName) {
    const injectParams = [];
    if (this.productData["confirm-purchase-button-label"]) {
      injectParams.push({
        name: "cta",
        value: this.productData["confirm-purchase-button-label"],
      });
    }
    if (this.productData["product-image"]) {
      injectParams.push({
        name: "productImage",
        value: this.productData["product-image"],
      });
    }
    return htmlLoader(
      this.templates[templateName] +
        (injectParams.length ? "?" + $.param(injectParams) : "")
    );
  }

  closeModalWarning() {
    return confirm("Are you sure?");
  }

  buyItem() {
    const that = this;

    this.forgetCurrentCart()
      .then(function (data) {
        return that.addProductToCart(that.productData["productId"]);
      })
      .then(function (data) {
        return that.showAddressForm();
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "An error happened while adding the product to the cart. Please try again. If the error persist please contact us."
        );
      });
  }

  /**
   * Forget the current cart (if any) in order to avoid adding unwanted products
   */
  forgetCurrentCart() {
    let newCartNumber = null;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: this.forgetCartActionUrl,
        method: "POST",
        success: function (responseData) {
          if (
            responseData["success"] === true &&
            responseData["newCartNumber"]
          ) {
            newCartNumber = responseData["newCartNumber"];
            console.log("Current cart cleared correctly");
            resolve(responseData);
          } else {
            // console.log('Could not empty the current cart', 'Response data error', responseData);
            reject("Could not empty the current cart");
          }
        },
        error: function (xhr, type) {
          reject("Could not empty the current cart");
        },
      });
    });
  }

  addProductToCart(productId) {
    return new Promise((resolve, reject) => {
      const data = [
        { name: "action", value: "commerce/cart/update-cart" },
        { name: "purchasableId", value: productId },
        { name: site.csrfTokenName, value: site.csrfTokenValue },
      ];

      $.ajax({
        type: "POST",
        url: "",
        data: $.param(data),
        success: function (responseData) {
          resolve(responseData);
          console.log("Product added to cart.");
        },
        error: function (xhr, type) {
          if (xhr.status === 302) {
            resolve();
            console.log("Product added to cart.");
          } else {
            reject("Could not add product to cart.");
          }
        },
      });
    });
  }

  showAddressForm() {
    const that = this;
    return new Promise((resolve, reject) => {
      that.popup.fire({
        html: that.getView("address"),
      });
      resolve();
    });
  }

  showPaymentForm() {
    const that = this;
    return new Promise((resolve, reject) => {
      that.popup.fire({
        html: that.getView("payment"),
      });
      resolve();
    });
  }

  submitPaymentForm($form) {
    return new Promise((resolve, reject) => {
      const $errorsContainer = $(".errors", $form);
      $errorsContainer.empty();
      const $payButton = $(".js-pay-button", $form);

      function showLoader() {
        $payButton.addClass("btn-variants--loader");
      }

      function hideLoader() {
        $payButton.removeClass("btn-variants--loader");
      }

      showLoader();

      this.circlePayments.processCircleTransaction($form, function () {
        alert("success!");
      });

      /*$.ajax({
        method: 'POST',
        url: '',
        dataType: "json",
        data: $form.serialize(),
        success: function(response){
          hideLoader();
          if(response.error !== undefined && response.paymentFormErrors !== undefined){
            
            for(const error in response.paymentFormErrors){
              $errorsContainer.append('<div>' + response.paymentFormErrors[error] + '</div>');
            }
          }
          else if(response.success !== undefined && response.success === true){
            resolve();
            ui.popup({
              icon: 'success',
              title: 'Coins have been added to your wallet.'
            }).then(function (){
              window.location.reload();
            });
          }
          console.log(response)
          resolve();
        },
        error: function (){
          hideLoader();
          reject('Could not update address data');
        }
      })*/
    });
  }

  submitAjaxForm($form) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: "POST",
        url: "",
        dataType: "json",
        data: $form.serialize(),
        success: function (response) {
          console.log(response);
          resolve();
        },
        error: function () {
          reject("Could not update address data");
        },
      });
    });
  }
}

export { DigitalProductCheckout };
