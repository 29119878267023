class RegisterHandlebarsHelpers {
  init(){
    const that = this;
    Handlebars.registerHelper({
      /* Prints "time ago" dates - eg. 2 seconds ago */
      timeSince: function (dateString) {
        var date = new Date(dateString);
        return that._timeSince(date);
      },

      random: function (toNumber, startFromOne = true) {
        var number = isNaN(toNumber) ? 100 : toNumber;
        var randomNumber = Math.floor(Math.random() * (number + 1));
        if(randomNumber === 0 && startFromOne){
          randomNumber = 1;
        }
        return randomNumber;
      },

      ifeq: function (a, b, options) {
        if (a && b && a == b) { return options.fn(this); }
        return options.inverse(this);
      },

      ifnoteq: function (a, b, options) {
        if (a != b) { return options.fn(this); }
        return options.inverse(this);
      },

      toLowerCase: function(str) {
        return str ? str.toLowerCase() : '';
      },

      formatCurrency: function(str) {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 2 }).format(str);
      },

      longNumberShort: function (value) {
        value = value.replace(/\,/g,'');
        if(isNaN(value)){
          return value;
        }

        return new Intl.NumberFormat('en-US', {
          notation: "compact",
          maximumFractionDigits: 1
        }).format(value);
      },

      // From monster.ui library https://github.com/2600hz/monster-ui/blob/b83ac60c9a5e433286959b9bec458d5018ebfabf/src/js/lib/monster.ui.js
      coalesce: function() {
        var args = $(arguments).toArray();

        if (args.length < 2) {
          throw new Error('Handlebars Helper "coalesce" needs at least 2 parameters');
        }

        // Last argument is discarded because it is handlebars' options parameter
        for (let i = 0; i < args.length - 1; i++) {
          if (args[i] != null) {
            return args[i];
          }
        }
        return null;
      },
    })
  }

  /**
   * Utility to format a date to 'timeago'
   * Credits: https://stackoverflow.com/a/3177838
   *
   * @param date
   * @returns {string}
   */
  _timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    let secondsAgo = Math.floor(seconds);
    return (isNaN(secondsAgo) ? '' : secondsAgo) + " seconds";
  }

}

export {RegisterHandlebarsHelpers};