import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { CSS3DRenderer, CSS3DObject, CSS3DSprite } from 'three/addons/renderers/CSS3DRenderer.js';
import { GLTFLoader } from  "three/examples/jsm/loaders/GLTFLoader.js";

class RenderHyperweb {
  renderHyperWebTriggerSelector = "#hyperwebContainer";

  constructor(selector = null) {
    if (selector) {
      this.renderHyperWebTriggerSelector = selector;
    }
  }

  init() {
    const that = this;
    const hyperweb = document.getElementById("hyperweb");
    const loading = document.getElementById("hyperweb-loading");
    if (hyperweb) {
      let actionUrlListItemById = '/actions/arkh-api/products/list-item-by-id';
      let actionUrlGetCreationData = '/actions/arkh-api/item-data/get-creation-data';
      let camera, scene, renderer, object;
      let controls;
      let endpointLink = '/creations/feed.json?elements-per-page=1';
      let notAllDisplayed = true;
      let currentRequest = null;
      const clock = new THREE.Clock();
      const creationType =  hyperweb.dataset.type;
      const image = hyperweb.dataset.url;
      const hyperlinkUrl = hyperweb.dataset.link;
      const id = hyperweb.dataset.id;
      
      scene = new THREE.Scene();

      addCreation();

      function addCreation() {

        scene = new THREE.Scene();
        console.log('creationType', creationType);
        switch(true) {
          case creationType == 'Hyperlink':
            let html = createPost(creationType, image);
            
            const hyperlink = $(html).find('#hyperlink-image');
            const linkCreation = $(html).find('#link-creations');
            const skeleton = $(html).find('#skeleton');
            getPreview(hyperlinkUrl, hyperlink, skeleton, linkCreation);

            object = new CSS3DObject(html);
            object.position.set(0, 0, 0 );
            object.rotation.y = 0.25;
            object.transparent = true;

            scene.add(object);

            camera = new THREE.PerspectiveCamera(
              70, window.innerWidth / window.innerHeight, 1, 1000
            );
            camera.position.set(0, 0, 700);
            camera.layers.enable( 0 ); // enabled by default
            camera.layers.enable( 1 );
            camera.layers.enable( 2 );

            renderer = new CSS3DRenderer();
            renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
            hyperweb.appendChild(renderer.domElement);

            controls = new OrbitControls( camera, renderer.domElement );
            controls.target.set(0, 25, 0);
            controls.enablePan = false;
            controls.enableZoom = true;
            controls.update();
            
            tick(null, controls);
            loading.classList.add('hidden');
            break;
          case creationType == 'Voxels' || creationType == 'Media':
            queryCreation(id)
              .then(responseData => {
                getCreationData(responseData)
                  .then(function (response) {
                    if (response.voxel) {
                      makeVoxel(response);
                      loading.classList.add('hidden');
                    } else if (response.creationData) {
                      if(is3dModel(response.creationData)){
                        scene.background = new THREE.Color(0xFEFBFF);
                        camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
                        camera.position.set( 0, 0, 8);
                        
                        const loader = new GLTFLoader();
                        let box = new THREE.Box3();
                        let objects = [];
                        loader.load( response.creationData.split('?')[0], function ( gltf ) {
                          scene.add( gltf.scene );
                          objects.push(gltf.scene);
                          box.setFromObject( gltf.scene );
                          const vectorSize = box.getSize(new THREE.Vector3());
                          camera.position.z = vectorSize.z * 5;
                          camera.position.x = vectorSize.x * 0.5;
                          camera.position.y = vectorSize.y * 2;

                          tick(gltf.scene, controls);
                        } );

                        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
                        hemiLight.position.set(0, 400, 0);
                        scene.add(hemiLight);

                        const directionalLight = new THREE.DirectionalLight(0xffffff);
                        directionalLight.position.set(0, 200, 100);
                        scene.add(directionalLight);

                        renderer = new THREE.WebGLRenderer( { antialias: true } );
                        renderer.setPixelRatio( window.devicePixelRatio );
                        renderer.setSize( window.innerWidth, window.innerHeight );
                        renderer.toneMapping = THREE.ACESFilmicToneMapping;
                        renderer.toneMappingExposure = 1;
                        renderer.outputEncoding = THREE.sRGBEncoding;

                        controls = new OrbitControls( camera, renderer.domElement );
                        controls.enableDamping = true;

                        hyperweb.appendChild(renderer.domElement);
                        loading.classList.add('hidden');
                      } else {
                        let defaultHtml = createPost(creationType, image);
                        object = new CSS3DObject(defaultHtml);
                        object.position.set(0, 0, 0 );
                        object.rotation.y = 0.25;

                        scene.add(object)

                        camera = new THREE.PerspectiveCamera(
                          70, window.innerWidth / window.innerHeight, 1, 1000
                        );
                        camera.position.set(0, 0, 700);
                        camera.layers.enable( 0 ); // enabled by default
                        camera.layers.enable( 1 );
                        camera.layers.enable( 2 );

                        renderer = new CSS3DRenderer();
                        renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
                        hyperweb.appendChild(renderer.domElement);

                        controls = new OrbitControls( camera, renderer.domElement );
                        controls.target.set(0, 25, 0);
                        controls.enablePan = false;
                        controls.enableZoom = true;
                        controls.update();

                        tick(object, controls)
                        loading.classList.add('hidden');
                      }
                    } else {
                      let defaultHtml = createPost(creationType, image);
                      object = new CSS3DObject(defaultHtml);
                      object.position.set(0, 0, 0 );
                      object.rotation.y = 0.25;

                      scene.add(object)

                      camera = new THREE.PerspectiveCamera(
                        70, window.innerWidth / window.innerHeight, 1, 1000
                      );
                      camera.position.set(0, 0, 700);
                      camera.layers.enable( 0 ); // enabled by default
                      camera.layers.enable( 1 );
                      camera.layers.enable( 2 );

                      renderer = new CSS3DRenderer();
                      renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
                      hyperweb.appendChild(renderer.domElement);

                      controls = new OrbitControls( camera, renderer.domElement );
                      controls.target.set(0, 25, 0);
                      controls.enablePan = false;
                      controls.enableZoom = true;
                      controls.update();

                      tick(object, controls)
                      loading.classList.add('hidden');
                    }
                  })
                  .catch((response) => {
                    console.log('creation data', response);
                    let defaultHtml = createPost(creationType, image);
                    object = new CSS3DObject(defaultHtml);
                    object.position.set(0, 0, 0 );
                    object.rotation.y = 0.25;
                    scene.add(object);

                    camera = new THREE.PerspectiveCamera(
                      70, window.innerWidth / window.innerHeight, 1, 1000
                    );
                    camera.position.set(0, 0, 700);
                    camera.layers.enable( 0 ); // enabled by default
                    camera.layers.enable( 1 );
                    camera.layers.enable( 2 );

                    renderer = new CSS3DRenderer();
                    renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
                    hyperweb.appendChild(renderer.domElement);

                    controls = new OrbitControls( camera, renderer.domElement );
                    controls.target.set(0, 25, 0);
                    controls.enablePan = false;
                    controls.enableZoom = true;
                    controls.update();

                    tick(object, controls);
                    loading.classList.add('hidden');
                  });
              })
              .catch((response) => {
                console.log('query log', response);
                let defaultHtml = createPost(creationType, image);
                object = new CSS3DObject(defaultHtml);
                object.position.set(0, 0, 0 );
                object.rotation.y = 0.25;
                scene.add(object);

                camera = new THREE.PerspectiveCamera(
                  70, window.innerWidth / window.innerHeight, 1, 1000
                );
                camera.position.set(0, 0, 700);
                camera.layers.enable( 0 ); // enabled by default
                camera.layers.enable( 1 );
                camera.layers.enable( 2 );

                renderer = new CSS3DRenderer();
                renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
                hyperweb.appendChild(renderer.domElement);

                controls = new OrbitControls( camera, renderer.domElement );
                controls.target.set(0, 25, 0);
                controls.enablePan = false;
                controls.enableZoom = true;
                controls.update();

                tick(object, controls);
                loading.classList.add('hidden');
              })
            break;
          default:
            let defaultHtml = createPost(creationType, image);
            object = new CSS3DObject(defaultHtml);
            object.position.set(0, 0, 0 );
            object.rotation.y = 0.25;
            scene.add(object);

            camera = new THREE.PerspectiveCamera(
              70, window.innerWidth / window.innerHeight, 1, 1000
            );
            camera.position.set(0, 0, 700);
            camera.layers.enable( 0 ); // enabled by default
            camera.layers.enable( 1 );
            camera.layers.enable( 2 );

            renderer = new CSS3DRenderer();
            renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
            hyperweb.appendChild(renderer.domElement);

            controls = new OrbitControls( camera, renderer.domElement );
            controls.target.set(0, 25, 0);
            controls.enablePan = false;
            controls.enableDamping = true;
            controls.enableZoom = true;
            controls.update();

            tick(object, controls);
            loading.classList.add('hidden');
        }
        // animate();

        window.addEventListener("resize", onWindowResize);
      }

      function onWindowResize() {
        camera.aspect = hyperweb.offsetWidth / hyperweb.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
      }

      function animate() {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      }

      function isFileType(filePath, extensionsArray){
        for (let i = 0; i < extensionsArray.length; i++) {
          if (filePath.includes(extensionsArray[i])) {
            return true;
          }
        }

        return false;
      }

      function is3dModel(filePath){
        const extensions = [
          '.glb',
          '.gltf'
        ]
        return isFileType(filePath, extensions)
      }

      function tick(object, controls) {
        const elapsedTime = clock.getElapsedTime();
        
        if(object) {
          object.rotation.y = elapsedTime * 0.1;
          object.rotation.x = elapsedTime * 0.1;
        }

        controls.update();

        renderer.render(scene, camera);

        window.requestAnimationFrame(() => tick(object, controls));
      }

      const feedWrapper = document.getElementById('js-feed-wrapper');

      function infiniteLoadCreations(onSuccessCallback) {
        currentRequest = $.ajax({
          url: endpointLink,
          method: 'GET',
          beforeSend: function () {
            // preloader.show();
          },
        }).always(function (returned) {
          // preloader.hide();
          currentRequest = null;
        }).done(function (response) {
          if (response.meta && typeof response.meta.pagination.links.next !== 'undefined') {
            endpointLink = response.meta.pagination.links.next;
          } else {
            endpointLink = null;
          }
          if (response.data && response.data.length) {
            $.each(response.data, function (index, article) {
              createPost(article);

              if(article['hyperlinkUrl']) {
                const hyperlink = $('#js-feed-wrapper').children().last().find('#hyperlink-image');
                const linkCreation = $('#js-feed-wrapper').children().last().find('#link-creations');
                const skeleton = $('#js-feed-wrapper').children().last().find('#skeleton');
                getPreview(article['hyperlinkUrl'], hyperlink, skeleton, linkCreation);
              }
            });

            if (onSuccessCallback) {
              onSuccessCallback();
            }
          }

          if(response.data.length == 0){
            notAllDisplayed = false;
          }

          if (response.data && response.data.length === 0) {
            // scrollerElement.append('<p class="text-center mt-8">' + noItemsMessage + '</p>');
            notAllDisplayed = false;
          }
        }).fail(function (data) {
          console.error('Could not load items in the infinite scroller section');
        })
      }
      
      function makeVoxel(response) {
        camera = new THREE.PerspectiveCamera( 35, hyperweb.offsetWidth / hyperweb.offsetHeight, 0.1, 99999 );
        camera.position.set( 0, 0, 6);

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
        hemiLight.position.set(0, 400, 0);
        scene.add(hemiLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0, 200, 100);
        directionalLight.castShadow = true;
        directionalLight.shadow.camera.top = 180;
        directionalLight.shadow.camera.bottom = -100;
        directionalLight.shadow.camera.left = -120;
        directionalLight.shadow.camera.right = 120;
        scene.add(directionalLight);

        let voxelColors = {};

        for(let color of response.voxel['Blocks']) {
          voxelColors[color.id] = color.hex;
        }

        let group = new THREE.Group();
        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        for(let voxel of response.voxel['Objects'][0].voxels) {
          let color = voxel.voxelBlockId;
          let material = new THREE.MeshBasicMaterial({ color: voxelColors[color] });
          const mesh = new THREE.Mesh( geometry, material );
          mesh.position.set(voxel.position.x, voxel.position.y, voxel.position.z)
          group.add(mesh);
        }
        
        group.rotation.y = Math.PI * 0.5;
        
        console.log('camera', camera.position.distanceTo(group.position));

        let box = new THREE.Box3();
        box.setFromObject( group );
        const vectorSize = box.getSize(new THREE.Vector3());
        console.log(vectorSize);
        
        
        let zPosition = Math.max(vectorSize.y, vectorSize.x, vectorSize.z) * 6;
        
        camera.position.z = zPosition;
        
        group.position.set(0, 0, 0)

        scene.add(group);

        controls = new OrbitControls(camera, hyperweb);
        controls.target.set(0, 0, 0);
        controls.enableDamping = true;
        controls.update();

        renderer = new THREE.WebGLRenderer({
          alpha: true,
        });
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.setSize(hyperweb.offsetWidth, hyperweb.offsetHeight);
        hyperweb.appendChild(renderer.domElement);

        tick(group, controls);
      }

      function createPost(creationType, articleImage) {
        let container = document.createElement("div");
        container.classList.add('mb-8', 'hyperweb-card', 'overflow-hidden', 'mx-1', 'w-[100%]', 'max-w-[500px]', 'bg-[#ffffff]', 'box-shadow--4', 'rounded-lg', 'mx-1');

        // let header = document.createElement("div");
        // header.classList.add('flex', 'justify-between', 'bg-offset-color', 'items-center', 'p-2');
        // let userContainer = document.createElement("div");
        // userContainer.classList.add('flex', 'items-center');
        // let avatar = document.createElement("a");
        // avatar.href = `/${article.creator.toLowerCase()}`;
        // avatar.classList.add('block', 'mr-3', 'flex', 'items-center');
        // let avatarImage = document.createElement("img");
        // avatarImage.classList.add('w-10', 'h-10', 'max-w-none', 'rounded-full', 'box-shadow--4')
        // avatarImage.attributes.alt = `${article.creator} avatar`;
        // if(article['creatorImage']) {
        //   avatarImage.src = article.creatorImage;
        // } else {
        //   avatarImage.src = '/assets/images/profile-pic-default.png';
        // }

        // let moreOption = document.createElement("img");
        // moreOption.id = "js-report-creation";
        // moreOption.setAttribute("data-title", article["title"]);
        // moreOption.setAttribute("data-id", article["id"]);
        // moreOption.setAttribute("data-creator", article["creatorId"]);
        // moreOption.classList.add('p-2', 'text-outline', 'hover:cursor-pointer', 'js-report-creation');
        // // moreOption.innerHTML = `<div> {{'flag'|feather}} </div>`;
        // moreOption.src = '/static-assets/images/icons/link.svg';

        // let userDetails = document.createElement("div");
        // let name = document.createElement("p");
        // name.classList.add('text-title-md', 'font-bold', 'my-0', 'leading-6', 'mb-[-4px]');
        // name.innerHTML = article.creatorFullName;
        //
        // let username = document.createElement("a");
        // username.href = `/${article.creator.toLowerCase()}`;
        // username.classList.add('text-primary', 'text-body-md', 'pt-[-8px]');
        // username.innerHTML = `@${article.creator}`

        let creationImage = document.createElement("div");
        creationImage.classList.add('flex', 'justify-center', 'w-[100%]', 'bg-transparent', 'creation-image', 'rounded-lg', 'overflow-hidden');

        if(creationType == 'Hyperlink') {
          let link = document.createElement("a");
          link.classList.add('relative', 'flex', 'flex-col', 'w-[100%]');
          link.id = "link-creations";

          let skeleton = document.createElement("div");
          skeleton.classList.add('skeleton', 'w-[100%]', 'absolute', 'top-0', 'z-10', 'h-[100%]');
          skeleton.id = "skeleton";
          link.appendChild(skeleton);

          let image = document.createElement("img");
          image.classList.add('rounded-sm', 'overflow-hidden', 'w-[100%]', 'h-[auto]');
          image.id = 'hyperlink-image';
          image.alt = "creation";
          image.src = articleImage;
          link.appendChild(image);
          creationImage.append(link);
        } else {
          let creationContainer = document.createElement("div");
          creationContainer.classList.add('relative', 'flex', 'flex-col', 'w-[100%]', 'bg-offset-color');
          let image = document.createElement("img");
          image.classList.add('rounded-sm', 'overflow-hidden', 'w-[100%]', 'h-[auto]');
          image.src = articleImage;
          let title = document.createElement("p");
          title.classList.add('px-4', 'mt-2', 'text-title-lg', 'font-bold', 'link-content', 'leading-7');
          // title.innerText = article.title;
          let blurb = document.createElement("p");
          blurb.classList.add('px-4', 'text-body-md', 'link-content');
          // blurb.innerText = article.blurb;
          creationContainer.appendChild(image);
          // creationContainer.appendChild(title);
          // creationContainer.appendChild(blurb);
          creationImage.append(creationContainer);
        }

        // const likeCountContainer = document.createElement("div");
        // likeCountContainer.classList.add('py-2', 'px-4', 'bg-offset-color');
        // const likeCount = document.createElement("button");
        // likeCountContainer.appendChild(likeCount);
        // likeCount.classList.add('flex', 'items-center', 'space-x-2');
        // likeCount.setAttribute("data-toggle-like-url", article["likeToggleActionUrl"]);
        // likeCount.setAttribute("data-current-likes-count", article["likesCount"]);
        // if(article["isLiked"]) {
        //   likeCount.setAttribute("data-current-like-status", "liked");
        //   let icon = document.createElement("img");
        //   icon.src = '/static-assets/images/icons/filled-thumbs-up.svg';
        //   likeCount.appendChild(icon);
        // } else {
        //   likeCount.setAttribute("data-current-like-status", "notliked");
        //   let icon = document.createElement("img");
        //   icon.src = '/static-assets/images/icons/outline-thumbs-up.svg';
        //   likeCount.appendChild(icon);
        // }

        // if('{{currentUser|json_encode}}'){
        //
        // } else{
        //   likeCount.classList.add('js-requires-login');
        // }

        // let likeText = document.createElement("div");
        // likeText.innerText = `${article.likesCount} ${article.likesCount == 1? 'like' : 'likes'}`
        // likeCount.appendChild(likeText);
        //
        // likeCount.addEventListener('click', (e) => {
        //   e.preventDefault();
        //   let currentLikesCount = likeCount.dataset.currentLikesCount;
        //   let currentLikeStatus = likeCount.dataset.currentLikeStatus;
        //   $.ajax({
        //     url: likeCount.dataset.toggleLikeUrl,
        //     method: 'POST',
        //     dataType: 'json',
        //     success: function (response) {
        //       if(response.success && response.success === true){
        //         if(response.items && response.items[0] && response.items[0].action){
        //           let action = response.items[0].action;
        //           if(action === 'added'){
        //             likeCount.children[0].src = '/static-assets/images/icons/filled-thumbs-up.svg';
        //             const likes = parseInt(currentLikesCount) + (currentLikeStatus === 'liked' ? 0 : 1);
        //             likeCount.children[1].innerText = `${likes} ${likes == 1? 'like' : 'likes'}`
        //           }
        //           else {
        //             likeCount.children[0].src = '/static-assets/images/icons/outline-thumbs-up.svg';
        //             const likes = parseInt(currentLikesCount) - (currentLikeStatus === 'liked' ? 1 : 0);
        //             likeCount.children[1].innerText = `${likes} ${likes == 1? 'like' : 'likes'}`
        //           }
        //         }
        //       }
        //       else {
        //         ui.toastWarning("We could not toggle like to the selected item, please try again");
        //       }
        //     }
        //   });
        // })

        // userDetails.appendChild(name);
        // userDetails.appendChild(username);
        // avatar.appendChild(avatarImage);
        // userContainer.appendChild(avatar);
        // userContainer.appendChild(userDetails);
        // header.appendChild(userContainer);
        // header.appendChild(moreOption);
        // container.appendChild(header);
        container.appendChild(creationImage);
        // container.appendChild(likeCountContainer);
        // feedWrapper.appendChild(container);
        return container;
      }

      function getCreationData(responseData) {
        return new Promise((resolve, reject) => {
          const creationImage = responseData.creationImage;
          const creationData = responseData.creationData;
          const productReference = responseData.productReference;

          $.ajax({
            url: actionUrlGetCreationData,
            method: 'POST',
            data: {
              productReference: productReference
            },
            dataType: 'json',
          })
            .done(function (response) {
              if (response.success !== undefined) {
                resolve({
                  creationImage: creationImage,
                  creationData: creationData,
                  voxel: response.item || null
                });
              } else {
                reject("response.success is false");
              }
            })
            .fail(function (jqXHR, textStatus) {
              reject({
                jqXHR: jqXHR,
                textStatus: textStatus
              });
            });
        })
      }

      function queryCreation(itemId) {
        const that = this;

        return new Promise((resolve, reject) => {
          $.ajax({
            method: 'POST',
            url: actionUrlListItemById,
            data: {
              sessionToken: 'n/a',
              username: 'n/a',
              id: itemId
            },
            dataType: 'json',
          })
            .done(function (response) {
              resolve(response);
            })
            .fail(function (qXHR, textStatus) {
              reject({
                jqXHR: jqXHR,
                textStatus: textStatus
              });
            });
        })
      }


      function extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
          hostname = url.split('/')[2];
        } else {
          hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
      }


      function getPreview(link, hyperlink, skeleton, linkCreation) {
        const hostname = extractHostname(link);
        

        if((hostname.includes('youtube') || hostname.includes('youtu.be')) && link.includes('watch')) {
          if($('#hyperlink-title')) {
            $('#hyperlink-title').text('YouTube');
          }
          getYoutubeEmbed(link, hyperlink, skeleton, linkCreation);
        } else if(hostname.includes('tiktok')) {
          if($('#hyperlink-title')) {
            $('#hyperlink-title').text('TikTok');
          }
          getTikTokEmbed(link, hyperlink, skeleton, linkCreation);
        } else if(hostname.includes('spotify')) {
          if($('#hyperlink-title')) {
            $('#hyperlink-title').text('Spotify');
          }
          getSpotifyEmbed(link, hyperlink, skeleton, linkCreation);
        } else {
          if($('#hyperlink-title')) {
            $('#hyperlink-title').text(hostname);
          }
          getRegularLinkEmbed(link, hyperlink, skeleton, linkCreation);
        }
      }

      function getYoutubeEmbed(link, hyperlink, skeleton, linkCreation) {
        $.ajax({
          type: "GET",
          url: `/actions/arkh-api/products/youtube-preview?q=${link}`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          contentType: 'json',
        }).always(function (returned) {
        }).done(function (response) {
          if (response.success) {
            if (response.item) {
              linkCreation.children('#hyperlink-image').remove();
              linkCreation.html(response.item.html)
              linkCreation.children('iframe').addClass('w-[100%] h-[350px]')
              skeleton.addClass('hidden');
            }
            skeleton.addClass('hidden');
          } else {
            console.log('error', response);
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          }
        }).fail(function (data) {
          console.log(data);
          let hyperLink = document.createElement("p");
          hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
          hyperLink.innerHTML = link;
          linkCreation.append(hyperLink);
          skeleton.addClass('hidden');
        });
      }


      function getSpotifyEmbed(link, hyperlink, skeleton, linkCreation) {
        $.ajax({
          type: "GET",
          url: `/actions/arkh-api/products/spotify-preview?q=${link}`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          contentType: 'json',
        }).always(function (returned) {
        }).done(function (response) {
          if (response.success) {
            if (response.item) {
              hyperlink.remove();
              linkCreation.html(response.item.html);
              linkCreation.children('iframe').addClass('w-[100%]')
              skeleton.addClass('hidden');
            }
            skeleton.addClass('hidden');
          } else {
            console.log('error', response);
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          }
        }).fail(function (data) {
          console.log(data);
          let hyperLink = document.createElement("p");
          hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
          hyperLink.innerHTML = link;
          linkCreation.append(hyperLink);
          skeleton.addClass('hidden');
        });
      }

      function getTikTokEmbed(link, hyperlink, skeleton, linkCreation) {
        $.ajax({
          type: "GET",
          url: `/actions/arkh-api/products/tiktok-preview?q=${link.split('?')[0]}`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          contentType: 'json',
        }).always(function (returned) {
        }).done(function (response) {
          if (response.success) {
            if (response.item) {
              linkCreation.children('#hyperlink-image').remove();
              linkCreation.html(response.item.html)
              skeleton.addClass('hidden');
            }
            skeleton.addClass('hidden');
          } else {
            console.log('error', response);
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          }
        }).fail(function (data) {
          console.log(data);
          let hyperLink = document.createElement("p");
          hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
          hyperLink.innerHTML = link;
          linkCreation.append(hyperLink);
          skeleton.addClass('hidden');
        });
      }

      function getRegularLinkEmbed(link, hyperlink, skeleton, linkCreation) {
        $.ajax({
          type: "GET",
          url: `/actions/arkh-api/products/get-link-preview?q=${link}`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          contentType: 'json',
        }).always(function (returned) {
        }).done(function (response) {
          if (response) {
            linkCreation.addClass('border-disabled border-[1px]  border-solid rounded-md')
            if (response.cover) {
              hyperlink.attr('src', response.cover);
            }
            if (response.title) {
              let title = document.createElement("p");
              title.classList.add('px-4', 'my-2', 'text-title-lg', 'font-bold', 'link-content', 'leading-7');
              title.innerHTML = response.title;
              linkCreation.append(title);
            }
            if (response.description && response.description != 'NULL') {
              let description = document.createElement("p");
              description.classList.add('px-4', 'text-body-md', 'link-content');
              description.innerHTML = response.description;
              linkCreation.append(description);
            }
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-4', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          } else {
            console.log('error', response);
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-4', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          }
        }).fail(function (data) {
          console.log(data);
          let hyperLink = document.createElement("p");
          hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
          hyperLink.innerHTML = link;
          linkCreation.append(hyperLink);
          skeleton.addClass('hidden');
        });
      }
    }
  }
}

export { RenderHyperweb };
