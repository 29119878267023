import makeId from "../utils/make-id";

/**
 * https://codepen.io/yy/pen/vOYqYV
 */
export default function jqueryCustomSelect(selector = ".select") {
  $(selector).each(function () {
    var classes = $(this).attr("class"),
      id = $(this).attr("id"),
      name = $(this).attr("name");
    if (!id) {
      id = makeId(5);
      $(this).attr("id", id);
    }
    var template = '<div class="' + classes + '">';
    var selectedOption = $(this).find("option:selected");
    var selectedText = "n/a";
    if (selectedOption) {
      selectedText = selectedOption.text();
    } else {
      selectedText = $(this).find("option").first().text();
    }
    template +=
      '<span class="custom-select-trigger">' + selectedText + "</span>";
    template += '<div class="custom-options">';
    $(this)
      .find("option")
      .each(function () {
        template +=
          '<span class="custom-option ' +
          $(this).attr("class") +
          '" data-value="' +
          $(this).attr("value") +
          '">' +
          $(this).html() +
          "</span>";
      });
    template += "</div></div>";

    $(this).wrap(
      '<div class="custom-select-wrapper" data-select-id="' + id + '"></div>'
    );
    $(this).hide();
    $(this).after(template);
  });
  $(".custom-option:first-of-type").hover(
    function () {
      $(this).parents(".custom-options").addClass("option-hover");
    },
    function () {
      $(this).parents(".custom-options").removeClass("option-hover");
    }
  );
  $(".custom-select-trigger").on("click", function (event) {
    $("html").one("click", function () {
      $(selector).removeClass("opened");
    });
    $(this).parents(selector).toggleClass("opened");
    event.stopPropagation();
  });
  $(".custom-option").on("click", function () {
    // $(elector).trigger('onchange');
    const selectId = $(this)
      .parents(".custom-select-wrapper")
      .data("select-id");
    const select = $("#" + selectId);
    select.find("option").removeAttr("selected");
    $("#" + selectId + " option[value=" + $(this).data("value") + "]").attr(
      "selected",
      "selected"
    );
    select.val($(this).data("value"));
    // select.trigger('onchange');
    var event = new Event("change");
    select[0].dispatchEvent(event);
    // $('option[value=' + $(this).data("value") + ']', $(selector)).prop('selected', true);
    $(this)
      .parents(".custom-select-wrapper")
      .find("select")
      .val($(this).data("value"));
    $(this)
      .parents(".custom-options")
      .find(".custom-option")
      .removeClass("selection");
    $(this).addClass("selection");
    $(this).parents(selector).removeClass("opened");
    $(this)
      .parents(selector)
      .find(".custom-select-trigger")
      .text($(this).text());
  });
}
