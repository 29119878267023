import redirectTo from "../utils/redirect-to-url";

class BuyItems {
  buyItemTriggerSelector = ".js-buy-item";

  constructor(selector = null) {
    if (selector) {
      this.buyItemTriggerSelector = selector;
    }
  }

  getActionOnClick() {
    const that = this;
    return function (e) {
      e.preventDefault();
      e.stopPropagation();
      that.buyItemHandler($(this));
    };
  }

  init() {
    const that = this;
    $(document).on(
      "click",
      this.buyItemTriggerSelector,
      that.getActionOnClick()
    );
  }

  buyItemHandler($element) {
    const creationId = $element.data("creation-id");
    const price = $element.data("price") || 0;
    const purchaseUrl = $element.data("purchase-url");
    const coinsUrl = $element.data("coins-url");

    if (user.accountBalance < price) {
      this.insufficientFundsHandler(coinsUrl);
    } else {
      this.startPurchaseProcess({
        price: price,
        creationId: creationId,
        purchaseUrl: purchaseUrl,
      });
    }
  }

  insufficientFundsHandler(coinsUrl) {
    ui.popup({
      title: "Insufficient Funds",
      html: "You need more coins to purchase this item!",
      cancelButtonText: "Cancel",
      confirmButtonText: "Get",
      showCancelButton: true,
      icon: null,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        redirectTo(coinsUrl);
      }
    });
  }

  startPurchaseProcess(settings) {
    const that = this;
    let html =
      "Please confirm that you would like to purchase this item with <br /><strong>" +
      settings.price +
      " coins.</strong>";
    if (!settings.price) {
      html =
        "Please confirm that you would like to add this item to your backpack for <strong>FREE</strong>.";
    }
    ui.popup({
      title: "Confirm Purchase?",
      html: html,
      icon: null,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      preConfirm: (confirm) => {
        Swal.showLoading();
        return new Promise((resolve, reject) => {
          var sendData = {};
          sendData[site.csrfTokenName] = site.csrfTokenValue;
          sendData["price"] = settings.price;
          sendData["creationId"] = settings.creationId;

          $.ajax({
            method: "POST",
            url: settings.purchaseUrl,
            cache: false,
            data: sendData,
          }).done(function (purchasedata) {
            if (purchasedata.success) {
              resolve();
              ui.toastSuccess("Item has been added to backpack.").then(() => {
                if (window.location.pathname != "/marketplace") {
                  window.location.reload();
                }
              });
            } else {
              reject();
              console.log(purchasedata);
              ui.popup({
                icon: "error",
                title: null,
                html: purchasedata.message || "Please try again",
                confirmButtonText: "Close",
              });
            }
          });
        });
      },
    });
  }
}

export { BuyItems };
