class InfiniteScroller {

  infiniteScrollerTemplateSelector = '.js-infinite_scroller_list';
  
  constructor(selector = null) {
    if(selector){
      this.infiniteScrollerTemplateSelector = selector;
    }
  }
  
  init() {

    const templates = $(this.infiniteScrollerTemplateSelector);

    for (let i = 0; i < templates.length; i++) {

    }

    templates.each(function (i, templateElement) {
      let template = $(templateElement);
      let apiEndpoint = template.data('query-url');
      let userIsLogged = template.data('user-is-logged');
      let currentUserId = template.data('current-user-id');
      let insertAfterSelector = template.data('insert-after-selector');
      let scrollerContainerId = template.data('scroller-container-id');
      let noItemsMessage = template.data('no-items-message') || 'THERE ARE NO ITEMS';
      let disableInfiniteScrolling = template.data('disable-infinite-scrolling') || false;
      let defaultScrollerClass = template.data('scroller-element-class') || 'c-infinite-scroller';
      let scrollerElement;
      if (insertAfterSelector) {
        scrollerElement = $(insertAfterSelector);
        scrollerElement.addClass(defaultScrollerClass);
      } else {
        scrollerElement = $('<div class="' + defaultScrollerClass + '"></div>');
        scrollerElement.insertAfter(templateElement);
      }
      let actionUrl = template.data('action-url') || null;

      if (scrollerContainerId) {
        scrollerElement.attr('id', scrollerContainerId);
      }

      createInfiniteScroller({
        apiEndpoint: apiEndpoint,
        scrollerElement: scrollerElement,
        template: template,
        actionUrl: actionUrl,
        disableInfiniteScrolling: disableInfiniteScrolling,
        userIsLogged: userIsLogged,
        currentUserId: currentUserId,
        noItemsMessage: noItemsMessage
      });
    });

    /**
     * Creates an infinite scroller listing
     * @param settings
     */
    function createInfiniteScroller(settings) {
      let scrollerElement = settings.scrollerElement;
      let templateElement = settings.template;
      let userIsLogged = settings.userIsLogged;
      let currentUserId = settings.currentUserId;
      let noItemsMessage = settings.noItemsMessage;
      let actionUrl = settings.actionUrl || null;
      let disableInfiniteScrolling = settings.disableInfiniteScrolling || false;
      let endpointlink = settings.apiEndpoint;
      const sentinel = $('<div></div>').addClass('c-infinite-scroller__sentinel');
      const preloader = $('<div></div>').addClass('c-infinite-scroller__preloader');
      scrollerElement.after(preloader);
      const preloaderImage = $('<img />').attr('src', '/static-assets/images/loading.svg').addClass('c-infinite-scroller__loading-img')
      preloader.append(preloaderImage);
      const handlebarTemplate = Handlebars.compile(templateElement.html());
      let notAllDisplayed = true;
      let currentRequest = null;
      
      $(document).on('click', '.creation-card-marketplace-global', function() {
        preloader.hide();
        let scrollPosition = $(document).scrollTop();
        let html = $('.js-lazy-wrapper').html();
        sessionStorage.setItem('scrollPosition', scrollPosition);
        sessionStorage.setItem('endpointLink', endpointlink);
        sessionStorage.setItem("html", html);
      });

      function infiniteLoadCreations(onSuccessCallback) {
        if(window.location.pathname.includes('/marketplace') && sessionStorage.getItem("html")) {
          scrollerElement.append(sessionStorage.getItem('html'));
          endpointlink = sessionStorage.getItem('endpointLink');
          $(document).scrollTop(sessionStorage.getItem('scrollPosition'));
          sessionStorage.removeItem("html");
          sessionStorage.removeItem("endpointLink");
          sessionStorage.removeItem("scrollPosition");
        }
        if (endpointlink) {
          currentRequest = $.ajax({
            url: endpointlink,
            method: 'GET',
            beforeSend: function () {
              preloader.show();
            },
          }).always(function (returned) {
            preloader.hide();
            currentRequest = null;
          }).done(function (response) {
            if (response.meta && typeof response.meta.pagination.links.next !== 'undefined') {
              endpointlink = response.meta.pagination.links.next;
            } else {
              endpointlink = null;
            }
            if (response.data && response.data.length) {
              $.each(response.data, function (index, article) {
                if (actionUrl) {
                  article['actionUrl'] = actionUrl;
                }
                // Inject user status into the template data
                article['userIsLogged'] = userIsLogged;
                article['currentUserId'] = currentUserId;

                var html = handlebarTemplate(article);
                scrollerElement.append(html);
                
                if(article['hyperlinkUrl']) {
                  const hyperlink = scrollerElement.children().last().find('#hyperlink-image');
                  const linkCreation = scrollerElement.children().last().find('#link-creations');
                  const skeleton = scrollerElement.children().last().find('#skeleton');
                  getPreview(article['hyperlinkUrl'], hyperlink, skeleton, linkCreation);
                }
                
                scrollerElement.after(preloader);
              });

              if (onSuccessCallback) {
                onSuccessCallback();
              }
            }

            if(response.data.length == 0){
              notAllDisplayed = false;
            }

            if (response.data && response.data.length === 0) {
              scrollerElement.append('<p class="text-center mt-8">' + noItemsMessage + '</p>');
              notAllDisplayed = false;
            }
          }).fail(function (data) {
            console.error('Could not load items in the infinite scroller section');
          })
        }
      }

      function appendToScroller() {
        /*if ($('>div', scrollerElement).length >= 3) {
          var penultimateChild = scrollerElement.find('>div:last-child').prev();
          sentinel.insertBefore(penultimateChild);
        } else {
          scrollerElement.append(sentinel);
        }*/
        scrollerElement.after(sentinel);
      }

      function getPreview(link, hyperlink, skeleton, linkCreation) {
        // if(link.includes('tiktok')) {
        //  
        // }
        $.ajax({
          type: "GET",
          url: `/actions/arkh-api/products/get-link-preview?q=${link}`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          contentType: 'json',
        }).always(function (returned) {
        }).done(function (response) {
          if (response) {
            if (response.cover) {
              hyperlink.attr('src', response.cover);
            }
            if (response.title) {
              let title = document.createElement("p");
              title.classList.add('px-4', 'my-2', 'text-title-lg', 'font-bold', 'link-content', 'leading-7');
              title.innerHTML = response.title;
              linkCreation.append(title);
            }
            if (response.description && response.description != 'NULL') {
              let description = document.createElement("p");
              description.classList.add('px-4', 'text-body-md', 'link-content');
              description.innerHTML = response.description;
              linkCreation.append(description);
            }
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
          } else {
            console.log('error', response);
            let hyperLink = document.createElement("p");
            hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
            hyperLink.innerHTML = link;
            linkCreation.append(hyperLink);
            skeleton.addClass('hidden');
            skeleton.addClass('hidden');
          }
        }).fail(function (data) {
          console.log(data);
          let hyperLink = document.createElement("p");
          hyperLink.classList.add('text-body-md', 'text-outline', 'overflow-hidden', 'text-ellipsis', 'px-4', 'mt-2', 'mb-2', 'whitespace-nowrap');
          hyperLink.innerHTML = link;
          linkCreation.append(hyperLink);
          skeleton.addClass('hidden');
          skeleton.addClass('hidden');
        });
      }


      function startObserving() {
        // /** credit https://googlechrome.github.io/samples/intersectionobserver/ */
        $(document).bind('wheel', function(e) {
          if(e.originalEvent.wheelDelta / 120 <= 0 && notAllDisplayed === true && currentRequest === null) {
            infiniteLoadCreations(function () {
              appendToScroller();
            });
          }
        });
        $(document).bind('touchmove', function(e) {
          if(notAllDisplayed === true && currentRequest === null) {
            infiniteLoadCreations(function () {
              appendToScroller();
            });
          }
        });
      }

      infiniteLoadCreations(function () {
        appendToScroller();
        if (disableInfiniteScrolling !== true) {
          startObserving();
        }
      });

    }
  }
}

export {InfiniteScroller};