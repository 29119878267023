import Swal from "sweetalert2";

class ModalPreviewer {
  canvas;
  $modalModelPreviewerWrapper;
  $modalModelPreviewerContainer;
  $modalModelPreviewerClose;
  $modalModelPreviewerLoading;
  $overlay;
  selectors;
  actionUrlListItemById = '/actions/arkh-api/products/list-item-by-id';
  actionUrlGetCreationData = '/actions/arkh-api/item-data/get-creation-data';
  modelIsLoading = false;
  startTime;
  secondsBeforeShowingWarning = 20;

  constructor(props = {}) {
    this.selectors = {
      canvasId: props.canvasId || 'modal-model-previewer-canvas',
      modalModelPreviewerWrapperId: props.modalModelPreviewerWrapperId || 'modal-model-previewer-wrapper',
      modalModelPreviewerContainer: props.modalModelPreviewerContainer || '#modal-model-previewer-container',
      modalModelPreviewerClose: props.modalModelPreviewerClose || '#modal-model-previewer-close',
      modalModelPreviewerLoading: props.modalModelPreviewerLoading || '#modal-model-previewer-loading',
      triggerSelector: '.js--previewer',
      overlay: '#modal-model-previewer-overlay'
    };
  }

  init() {
    this.canvas = document.getElementById(this.selectors.canvasId);
    this.$modalModelPreviewerWrapper = $('#' + this.selectors.modalModelPreviewerWrapperId);
    this.$modalModelPreviewerContainer = $(this.selectors.modalModelPreviewerContainer);
    this.$modalModelPreviewerClose = $(this.selectors.modalModelPreviewerClose);
    this.$modalModelPreviewerLoading = $(this.selectors.modalModelPreviewerLoading);
    this.$overlay = $(this.selectors.overlay);
    const that = this;

    this.$modalModelPreviewerClose.on('click pointerdown', function (e) {
      that.closeModal();
    })
    
    this.$modalModelPreviewerLoading.on('click', function(){
      that.closeModal();
    })

    this.$modalModelPreviewerWrapper.on('mousedown', function (e) {
      if (e.target.id === that.selectors.modalModelPreviewerWrapperId) {
        that.closeModal();
      }
    })

    var mouseDown = false;
    this.$modalModelPreviewerWrapper.on('mousedown touchstart', function(event) {
      event.preventDefault();
      mouseDown = true;
    });
    this.$modalModelPreviewerWrapper.on('mousemove touchmove', function(event) {
      event.preventDefault();
      if(mouseDown) {
        // Do something here.
      }
    });
    this.$modalModelPreviewerWrapper.on('mouseup touchend', function(event) {
      // Capture this event anywhere in the document, since the mouse may leave our element while mouse is down and then the 'up' event will not fire within the element.
      mouseDown = false;
    });

    $(document).on('click', that.selectors.triggerSelector, function (e) {
      e.preventDefault();
      e.stopPropagation();
      const $elm = $(this);
      const itemId = $elm.data('item-id');

      that.startTime = new Date();
      
      if (!itemId) {
        throw new Error('Item id is missing from the data attribute data-item-id.');
      }

      that.$modalModelPreviewerWrapper.fadeIn(200);
      that.showLoader();
      that.modelIsLoading = true;

      that.queryCreation(itemId)
        .then(responseData => {
          that.getCreationData(responseData)
            .then(function (response) {
              // console.log(response.creationImage, response.creationData, response.voxel);
              if (response.voxel) {
                that.showVoxel(response.voxel)
              } else if (response.creationData) {
                if(that.isImageFile(response.creationData)) {
                  that.modelIsLoading = false;
                  that.hideLoader();
                  that.showImage(response.creationData, ()=>{that.closeModal()});
                }
                else if(that.is3dModel(response.creationData)){
                  that.show3dModel(response.creationData);
                }
                else {
                  ui.popup({
                    title: null,
                    icon: 'error',
                    html: 'An error happened while loading the preview'
                  })
                }
              } else if (that.isImageFile(response.creationImage)) {
                // Fallback to the image thumb if noting is found
                that.hideLoader();
                that.modelIsLoading = false;
                that.showImage(response.creationImage, ()=>{that.closeModal()});
              } else {
                ui.popup({
                  title: null,
                  icon: 'error',
                  html: 'An error happened while loading the preview'
                })
              }
            })
            .catch((response) => {
              console.log(response);
              that.errorUi(itemId)
            });
        })
        .catch((response) => {
          console.log(response);
          that.errorUi(itemId)
        })
    });
  }

  getCreationData(responseData) {
    const that = this;
    return new Promise((resolve, reject) => {
      const creationImage = responseData.creationImage;
      const creationData = responseData.creationData;
      const productReference = responseData.productReference;

      $.ajax({
        url: that.actionUrlGetCreationData,
        method: 'POST',
        data: {
          productReference: productReference
        },
        dataType: 'json',
      })
        .done(function (response) {
          if (response.success !== undefined) {
            resolve({
              creationImage: creationImage,
              creationData: creationData,
              voxel: response.item || null
            });
          } else {
            reject("response.success is false");
          }
        })
        .fail(function (jqXHR, textStatus) {
          reject({
            jqXHR: jqXHR, 
            textStatus: textStatus
          });
        });
    })
  }

  queryCreation(itemId) {
    const that = this;

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: that.actionUrlListItemById,
        data: {
          sessionToken: 'n/a',
          username: 'n/a',
          id: itemId
        },
        dataType: 'json',
      })
        .done(function (response) {
          resolve(response);
        })
        .fail(function (qXHR, textStatus) {
          reject({
            jqXHR: jqXHR,
            textStatus: textStatus
          });
        });
    })
  }

  showImage(imageUrl, onCloseCallback = function () {
  }) {
    ui.popup({
      imageUrl: imageUrl,
      title: null,
      icon: null,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: 'modal-previewer'
      },
    }).then(() => {
      onCloseCallback();
    })
  }

  showLoader() {
    console.log('Show loader gif');
    this.$modalModelPreviewerLoading.show();
  }

  hideLoader() {
    console.log('Hide loader gif');
    this.$modalModelPreviewerLoading.hide();
  }

  errorUi = function (id) {
    console.error('Could not retrieve item ' + id);
    ui.toastError('An error occured while retrieving the creation data. Please try again.');
  }
  
  isFileType(filePath, extensionsArray){
    for (let i = 0; i < extensionsArray.length; i++) {
      if (filePath.includes(extensionsArray[i])) {
        return true;
      }
    }

    return false;
  }

  isImageFile(filePath) {
    const extensions = [
      '.jpg',
      '.gif',
      '.png',
      '.jpeg'
    ];

    return this.isFileType(filePath, extensions);
  }
  
  is3dModel(filePath){
    const extensions = [
      '.arkh', 
      '.glb',
      '.gltf'
    ]
    return this.isFileType(filePath, extensions)
  }

  show3dModel(itemUrl, onLoadCallback = function () {
  }) {
    this.loadPreviewer(itemUrl, false, onLoadCallback())
  }

  showVoxel(voxel, onLoadCallback = function () {
  }) {
    this.loadPreviewer(voxel, true, onLoadCallback());
  }

  loadPreviewer(item, isVoxel = true, onLoadCallback = function () {
  }) {
    const that = this;
    
    window.unityOnScreenshotTaken = function () {
      // Adding a delay to avoid seeing the screenshot flashing
      setTimeout(function(){
        that.modelIsLoading = false;
        onLoadCallback();
        that.hideLoader();
        that.$overlay.hide();
        // Avoid body scroll while dragging the model
        // $(document.body).css('overflow', 'hidden');
      }, 500);
    }

    const buildUrl = "/static-assets/previewer/Build";

    const config = {
      dataUrl: buildUrl + "/builds.data?v=" + site.assetsVersion,
      frameworkUrl: buildUrl + "/builds.framework.js?v=" + site.assetsVersion,
      codeUrl: buildUrl + "/builds.wasm?v=" + site.assetsVersion,
      streamingAssetsUrl: "StreamingAssets",
      companyName: "AppVolksLLC",
      productName: "ARKH Model Viewer",
      productVersion: "1.0",
    };
    
    that.$overlay.show();
    that.$modalModelPreviewerContainer.show();
    that.canvas.style.display = 'block';
    
    if (!window.unityInstance) {
      console.log('Modal previewer not exists. Creating a new instance');
      createUnityInstance(that.canvas, config, (progress) => {
      })
        .then((unityInstance) => {
          window.unityInstance = unityInstance;
          if (isVoxel) {
            // console.log(JSON.stringify(item));
            console.log('Loading voxel');
            if (item.BackgroundHex) {
              // set the canvas color background
            }
            // Adding a delay to avoid seeing the screenshot flashing
            setTimeout(function(){
              unityInstance.SendMessage("SceneController", "LoadVoxel", JSON.stringify(item));
            }, 300);
          } else {
            console.log('Loading 3d model file');
            unityInstance.SendMessage("SceneController", "LoadModel", item);
          }
        })
        .catch((message) => {
          console.error(message);
        })
    } else {
      console.log('Modal previewer already exists, using the one in memory');
      if (isVoxel) {
        console.log('Loading voxel');
        if (item.BackgroundHex) {
          // set the canvas color background
        }
        // Adding a delay to avoid seeing the screenshot flashing
        setTimeout(function(){
          unityInstance.SendMessage("SceneController", "LoadVoxel", JSON.stringify(item));
        }, 300);
      } else {
        console.log('Loading 3d model file');
        window.unityInstance.SendMessage("SceneController", "LoadModel", item);
      }
    }
  }

  noop(){
    console.log('test');
  }
  
  warnUserWaitLoading(){
    const that = this;
    ui.popup({
      icon: 'warning',
      title: null,
      html: 'The previewer is still loading, please wait until it finishes. If it takes too much you can click the "Force close" button to close the previewer',
      showCancelButton: true,
      confirmButtonText: 'Force close',
      cancelButtonText: 'OK'
    })
      .then((result)=>{
        if(result.isConfirmed){
          console.log('clicked force button');
          window.location.reload();
          // that.closeAllWindows();
        }
      })
  }
  
  closeModal() {
    if(this.modelIsLoading === false){
      this.closeAllWindows();
    }
    else {
      let elapsedTime = (new Date() - this.startTime) / 1000;
      if(elapsedTime > this.secondsBeforeShowingWarning){
        this.warnUserWaitLoading();  
      }
    }
  }
  
  closeAllWindows(){
    this.canvas.style.display = 'none';
    this.$modalModelPreviewerWrapper.fadeOut(400);
    this.$modalModelPreviewerContainer.hide();
    this.$overlay.hide();
    this.$modalModelPreviewerLoading.hide();
    // Restore scroll
    // $(document.body).css('overflow', 'auto');
  }
  
  /* Utility to monitor event type on a specific selector, leave commented if not used */
  /*monitorEvent(element){
    const target = document.querySelector(this.selectors.modalModelPreviewerClose);
    for (const key in target) {
      if(/^on/.test(key)) {
        const eventType = key.substr(2);
        if(!['pointermove', 'mousemove', 'pointerover', 'mouseover', 'pointerleave', 'mouseleave', 'pointerenter', 'mouseenter', 'mouseout', 'pointerout'].includes(eventType)){
          target.addEventListener(eventType, ()=>console.log(eventType + ' triggered'));
        }
      }
    }
  }*/
  
}

export {ModalPreviewer};