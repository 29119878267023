class DeveloperApplication {
  devApplicationTriggerSelector;

  constructor(selector = '.js-developer-application') {
    this.devApplicationTriggerSelector = selector;
  }

  init(){
    const that = this;
    $(this.devApplicationTriggerSelector).on('submit', function(e) {
      e.preventDefault();
      const data = $(this).serialize();

      $.ajax({
        type: "POST",
        url: '/freeform/submit',
        data: data,
        beforeSend: function () {
          $('.js-submit-app-button').addClass('btn-variants--loader')
        },
      }).always(function (returned) {
        $('.js-submit-app-button').removeClass('btn-variants--loader')
      }).done(function (response) {
        if (response.success) {
          ui.toastSuccess('Your application has been submitted successfully! Keep an eye on your inbox for an email from us.').then(function () {
            window.location.reload();
          })
        }
      }).fail(function (data) {
        ui.toastError("An error occurred: we could not send the text message. Please try again later!");
        console.error(data);
      })
    })
  }
}

export {DeveloperApplication};